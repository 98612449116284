import { ThemeProvider } from '@mui/material/styles'
import Head from 'next/head'
import { appWithTranslation } from 'next-i18next'
import { useEffect, useRef } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Hydrate } from 'react-query/hydration'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider as ReduxProvider } from 'react-redux'
import ErrorBoundary from '../components/error-boundary'
import muiTheme from '../mui.config'
import packageJson from '../package.json'
import { store } from '../store'
import { init } from '../util/firebase'
import '../styles/globals.css'
import ToastContainer from '../atoms/toast-container'
import { builder } from '@builder.io/react'
import { datadogRum } from '@datadog/browser-rum'

let persistor = persistStore(store)
builder.init(`${process.env.NEXT_PUBLIC_BUILDER_API_KEY}`)
datadogRum.init({
	applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
	clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
	// `site` refers to the Datadog site parameter of your organization
	// see https://docs.datadoghq.com/getting_started/site/
	site: process.env.NEXT_PUBLIC_DATADOG_SITE,
	service: process.env.NEXT_PUBLIC_DATADOG_SERVICE,
	env: process.env.NEXT_PUBLIC_DATADOG_ENV,
	// Specify a version number to identify the deployed version of your application in Datadog
	// version: '1.0.0',
	sessionSampleRate: 100,
	sessionReplaySampleRate: 100,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowedTracingUrls: [
		process.env.NEXT_PUBLIC_SITE_URL,
		/https:\/\/.*\.stufstorage\.com/,
		(url) => url.startsWith(process.env.NEXT_PUBLIC_SITE_URL)
	]
})

function MyApp({ Component, pageProps }) {
	const queryClientRef = useRef()

	const getData = () => {
		fetch('/meta.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		})
			.then(function (response) {
				console.log(response)
				console.log('packageJson.version', packageJson.version)
				return response.json()
			})
			.then(function (meta) {
				console.log('packageJson.version', packageJson.version)
				console.log(meta)
				console.log('Checking for newer version.')
				if (meta.version && packageJson.version === meta.version) {
					console.log('Newer version was NOT found')
				} else {
					console.log('Newer version was found')
				}
			})
	}
	useEffect(() => {
		init()
		getData()
	}, [])

	if (!queryClientRef.current) {
		queryClientRef.current = new QueryClient()
	}

	return (
		<>
			<Head>
				<link
					rel='apple-touch-icon'
					sizes='180x180'
					href='/favicon/apple-touch-icon.png'
				/>
				<link
					rel='icon'
					type='image/png'
					sizes='32x32'
					href='/favicon/favicon-32x32.png'
				/>
				<link
					rel='icon'
					type='image/png'
					sizes='16x16'
					href='/favicon/favicon-16x16.png'
				/>
				<link rel='manifest' href='/favicon/site.webmanifest' />
				<meta name='msapplication-TileColor' content='#001CFF' />
				<meta name='theme-color' content='#232323'></meta>
				<meta
					name='facebook-domain-verification'
					content='vu36rc2ix10i1tgstpwozw2u2ve362'
				/>
			</Head>
			<ReduxProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeProvider theme={muiTheme}>
						<QueryClientProvider client={queryClientRef.current}>
							<Hydrate state={pageProps.dehydratedState}>
								<ErrorBoundary>
									<Component {...pageProps} />
								</ErrorBoundary>
							</Hydrate>
						</QueryClientProvider>
						<ToastContainer />
					</ThemeProvider>
				</PersistGate>
			</ReduxProvider>
		</>
	)
}
export default appWithTranslation(MyApp)
