import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
import facilityUtil from '../util/facility'
import contractUtil from '../util/contract'

export const initialState = {
	updateUnitStatus: null,
	addUnitStatus: null,
	deleteUnitStatus: null,
	addUnitNeighborIdStatus: null,
	editUnitNeighborIdStatus: null,
	getAllContractsStatus: null,
	addContractStatus: null,
	editContractStatus: null,
	addFacilityStatus: null,
	editFacilityStatus: null,
	getMemberFacilitiesStatus: null,
	editUnitStatus: null,
	editUnitsStatus: null,
	facilitiesById: [],
	unitsWithContractInfo: [],
	onboardingChecklist: {
		moveInDetails: {
			viewed: false
		},
		virtualTour: {
			viewed: false
		},
		digitalKey: {
			viewed: false
		},
		setupLock: {
			viewed: false
		},
		account: {
			viewed: false
		}
	}
}

/*
	THUNKS
*/
export const addUnit = createAsyncThunk('dashboard/addUnit', async (unit) => {
	const apiClient = new ApiClient()
	return await apiClient
		.post('/unit', null, unit)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err)
		})
})

export const editUnit = createAsyncThunk('dashboard/editUnit', async (data) => {
	const apiClient = new ApiClient()
	return await apiClient
		.put('/unit', null, data)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err)
		})
})

export const editUnits = createAsyncThunk(
	'dashboard/editUnits',
	async (data) => {
		const { unit, id } = data
		const apiClient = new ApiClient()
		return await apiClient
			.put(`/facility/${id}/units`, null, unit)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const deleteUnit = createAsyncThunk(
	'dashboard/deleteUnit',
	async (unit) => {
		const apiClient = new ApiClient()
		return await apiClient
			.del('/unit', null, unit)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const addUnitNeighborId = createAsyncThunk(
	'dashboard/addUnitNeighborId',
	async (unit) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/unit_neighbor_id', null, unit)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const editUnitNeighborId = createAsyncThunk(
	'dashboard/editUnitNeighborId',
	async (unit) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/unit_neighbor_id', null, unit)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getAllContracts = createAsyncThunk(
	'dashboard/getAllContracts',
	async (orgId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/org/${orgId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const addContract = createAsyncThunk(
	'dashboard/addContract',
	async (contract) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/contract', null, contract)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const editContract = createAsyncThunk(
	'dashboard/editContract',
	async (contract) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/contract', null, contract)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const addFacility = createAsyncThunk(
	'dashboard/addFacility',
	async (facility) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/facility', null, facility)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const editFacility = createAsyncThunk(
	'dashboard/editFacility',
	async (facility) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/facility', null, facility)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		resetEditUnitStatus: (state) => {
			state.editUnitStatus = null
		},
		resetEditUnitsStatus: (state) => {
			state.editUnitsStatus = null
		},
		resetAddUnitStatus: (state) => {
			state.addUnitStatus = null
		},
		resetDeleteUnitStatus: (state) => {
			state.deleteUnitStatus = null
		},
		resetAddContractStatus: (state) => {
			state.addContractStatus = null
		},
		resetEditContractStatus: (state) => {
			state.editContractStatus = null
		},
		getFacilitiesById: (state, { payload }) => {
			state.facilitiesById = facilityUtil.groupFacilitiesById(payload)
		},
		resetEditFacilityStatus: (state) => {
			state.editFacilityStatus = null
		},
		resetAddFacilityStatus: (state) => {
			state.addFacilityStatus = null
		},
		setOnboardingChecklistItem: (state, { payload }) => {
			state.onboardingChecklist[payload.title] = { viewed: payload.status }
		},
		resetOnboardingChecklistStatus: (state) => {
			state.onboardingChecklist = initialState.onboardingChecklist
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(editUnit.pending, (state) => {
				state.editUnitStatus = 'PENDING'
			})
			.addCase(editUnit.fulfilled, (state, { payload }) => {
				state.editUnitStatus = 'FULFILLED'
			})
			.addCase(editUnit.rejected, (state, action) => {
				state.editUnitStatus = 'REJECTED'
			})
			.addCase(editUnits.pending, (state) => {
				state.editUnitsStatus = 'PENDING'
			})
			.addCase(editUnits.fulfilled, (state, { payload }) => {
				state.editUnitsStatus = 'FULFILLED'
			})
			.addCase(editUnits.rejected, (state, action) => {
				state.editUnitsStatus = 'REJECTED'
			})
			.addCase(addUnit.pending, (state) => {
				state.addUnitStatus = 'PENDING'
			})
			.addCase(addUnit.fulfilled, (state, { payload }) => {
				state.addUnitStatus = 'FULFILLED'
			})
			.addCase(addUnit.rejected, (state, action) => {
				state.addUnitStatus = 'REJECTED'
			})
			.addCase(deleteUnit.pending, (state) => {
				state.deleteUnitStatus = 'PENDING'
			})
			.addCase(deleteUnit.fulfilled, (state, { payload }) => {
				state.deleteUnitStatus = 'FULFILLED'
			})
			.addCase(deleteUnit.rejected, (state, action) => {
				state.deleteUnitStatus = 'REJECTED'
			})
			.addCase(addUnitNeighborId.pending, (state) => {
				state.addUnitNeighborIdStatus = 'PENDING'
			})
			.addCase(addUnitNeighborId.fulfilled, (state, { payload }) => {
				state.addUnitNeighborIdStatus = 'FULFILLED'
			})
			.addCase(addUnitNeighborId.rejected, (state, action) => {
				state.addUnitNeighborIdStatus = 'REJECTED'
			})
			.addCase(editUnitNeighborId.pending, (state) => {
				state.editUnitNeighborIdStatus = 'PENDING'
			})
			.addCase(editUnitNeighborId.fulfilled, (state, { payload }) => {
				state.editUnitNeighborIdStatus = 'FULFILLED'
			})
			.addCase(editUnitNeighborId.rejected, (state, action) => {
				state.editUnitNeighborIdStatus = 'REJECTED'
			})
			.addCase(getAllContracts.pending, (state) => {
				state.getAllContractsStatus = 'PENDING'
			})
			.addCase(getAllContracts.fulfilled, (state, { payload }) => {
				state.getAllContractsStatus = 'FULFILLED'
				state.unitsWithContractInfo =
					contractUtil.groupContractByFacility(payload)
			})
			.addCase(getAllContracts.rejected, (state, action) => {
				state.getAllContractsStatus = 'REJECTED'
			})
			.addCase(addContract.pending, (state) => {
				state.addContractStatus = 'PENDING'
			})
			.addCase(addContract.fulfilled, (state, { payload }) => {
				state.addContractStatus = 'FULFILLED'
			})
			.addCase(addContract.rejected, (state, action) => {
				state.addContractStatus = 'REJECTED'
			})
			.addCase(editContract.pending, (state) => {
				state.editContractStatus = 'PENDING'
			})
			.addCase(editContract.fulfilled, (state, { payload }) => {
				state.editContractStatus = 'FULFILLED'
			})
			.addCase(editContract.rejected, (state, action) => {
				state.editContractStatus = 'REJECTED'
			})
			.addCase(addFacility.pending, (state) => {
				state.addFacilityStatus = 'PENDING'
			})
			.addCase(addFacility.fulfilled, (state, { payload }) => {
				state.addFacilityStatus = 'FULFILLED'
			})
			.addCase(addFacility.rejected, (state, action) => {
				state.addFacilityStatus = 'REJECTED'
			})
			.addCase(editFacility.pending, (state) => {
				state.editFacilityStatus = 'PENDING'
			})
			.addCase(editFacility.fulfilled, (state, { payload }) => {
				state.editFacilityStatus = 'FULFILLED'
			})
			.addCase(editFacility.rejected, (state, action) => {
				state.editFacilityStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = dashboardSlice
export const {
	resetEditUnitStatus,
	resetEditUnitsStatus,
	resetAddUnitStatus,
	resetDeleteUnitStatus,
	resetAddContractStatus,
	resetEditContractStatus,
	resetEditFacilityStatus,
	getFacilitiesById,
	resetAddFacilityStatus,
	setOnboardingChecklistItem,
	resetOnboardingChecklistStatus
} = actions
export default reducer
