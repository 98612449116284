import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { firebaseEventTypes } from '../util/constants'
import ApiClient from '../util/apiClient'
import numberUtil from '../util/number'
import objectUtil from '../util/object'
import stringUtil from '../util/string'
import { logFBEvent } from '../util/firebase'

export const initialState = {
	cartOpen: false,
	putPersonError: {},
	postPersonStatus: null,
	putPersonStatus: null,
	postSendOnboardingOTPStatus: null,
	postVerifyOnboardingOTPStatus: null,
	postCreateCustomerStatus: null,
	postCreateSetupIntentStatus: null,
	postCalculateMoveInEstimateStatus: null,
	postWidgetCalculateMoveInEstimateStatus: null,
	postVerifyPromoCodeStatus: null,
	putContractStatus: null,
	getPersonPaymentStatus: null,
	getFacilityFeaturesStatus: null,
	getUnitStatus: null,
	getFacilityStatus: null,
	customer: {
		contract_id: null,
		person_id: null,
		phone_number_verified: false,
		first_name: null,
		last_name: null,
		reservation_notification: false,
		full_legal_name: null,
		phone_number: null,
		email_address: null,
		day_of_birth: null,
		month_of_birth: null,
		year_of_birth: null,
		physical_address_street_line_1: null,
		physical_address_street_line_2: null,
		physical_address_city: null,
		physical_address_state: '',
		physical_address_zip_code: null,
		military: {
			active_status: 'no',
			relation_to_military_member: '',
			military_active_member_name: null,
			military_id_number: null,
			military_id_state: '',
			commanding_officer_first_name: null,
			commanding_officer_last_name: null,
			commanding_officer_phone_number: null
		}
	},
	facility: {
		facility_id: null,
		unit_id: null,
		org_id: 1, // TODO Remove hard coded org_id. 1 = Stuf
		facility_feature_flags: []
	},
	unit: [{}],
	payment: {
		move_in_date: null,
		monthly_price: null,
		promo_id: null,
		promo_code: null,
		promo_banner_text: null,
		discount_id: null,
		payment_method: null,
		stripe_customer_id: null,
		si_secret: null,
		stripe_options: {
			clientSecret: null,
			appearance: {
				/*...*/
			}
		},
		total_due_on_move_in: null,
		current_month_payment: null,
		move_in_credit: null,
		first_month_pma: null,
		credit_end_date: null,
		after_19th_move_in_payment: null,
		next_month_payment: null,
		next_month_credit: null,
		next_month_payment_date: null,
		after_19th_rate: null,
		first_month_rate: null,
		first_month_insurance_premium: null,
		after_19th_insurance_premium: null,
		insurance_id: null,
		first_Month_Membership: null,
		after_19th_Membership: null,
		membership_id: null,
		insurancePremium: null,
		membershipPrice: null,
		paymentSchedule: []
	},
	widgetEstimate: {
		total_due_on_move_in: null,
		current_month_payment: null,
		move_in_credit: null,
		first_month_pma: null,
		after_19th_move_in_payment: null,
		next_month_payment: null,
		next_month_credit: null,
		next_month_payment_date: null,
		after_19th_rate: null,
		first_month_rate: null
	},
	progressStepNbr: null,
	onboardingOTP: null,
	pricingWidget: {
		pricing_widget_move_in_date: null,
		pricing_widget_selected_unit: null,
		pricing_widget_location: null
	},
	insurance: [],
	getInsuranceStatus: null,
	unitMembership: [],
	getUnitMembershipStatus: null,
	hasInsuranceSelected: false,
	getEncodedBookingInfoStatus: null
}

/*
	THUNKS
*/

export const postPerson = createAsyncThunk(
	'booking/post/person',
	async (body, { rejectWithValue }) => {
		// Remove empty, null or undefined values
		body = objectUtil.removeEmptyKeys(body)

		// Append role_id 1 for member
		body = { ...body, role_id: 1 }
		const apiClient = new ApiClient()
		try {
			const response = await apiClient.postv2('/person/v2', null, body)
			return response
		} catch (err) {
			return rejectWithValue(err)
		}
	}
)

export const sendOnboardingOTP = createAsyncThunk(
	'booking/post/sendOnboardingOTP',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/person/send-onboarding-otp', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const verifyOnboardingOTP = createAsyncThunk(
	'booking/post/verifyOnboardingOTP',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/person/verify-onboarding-otp', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putPerson = createAsyncThunk(
	'booking/put/person',
	async (body, { rejectWithValue }) => {
		// Remove empty, null or undefined values
		body = objectUtil.removeEmptyKeys(body)

		const apiClient = new ApiClient()
		return await apiClient
			.put('/person', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(JSON.stringify(rejectWithValue(err).payload))
			})
	}
)

export const postCreateSetupIntent = createAsyncThunk(
	'booking/post/payment/createSetupIntent',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/payment/createSetupIntent', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCreateCustomer = createAsyncThunk(
	'booking/post/payment/createCustomer',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/payment/v2/createCustomer', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCalculateMoveInEstimate = createAsyncThunk(
	'booking/post/payment/calculateMoveInEstimate',
	async (params) => {
		const apiClient = new ApiClient()
		const { body, fields } = params
		let queryUrl = '/payment/v2/calculateMoveInEstimate'
		if (fields) queryUrl += `?fields=${fields.join()}`
		return await apiClient
			.post(queryUrl, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postWidgetCalculateMoveInEstimate = createAsyncThunk(
	'booking/post/payment/widgetCalculateMoveInEstimate',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/payment/v2/calculateMoveInEstimate', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putSetDefaultPaymentMethod = createAsyncThunk(
	'booking/put/setDefaultPaymentMethod',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/payment/setDefaultPaymentMethod', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postVerifyPromoCode = createAsyncThunk(
	'booking/post/payment/verifyPromoCode',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/payment/verifyPromoCode', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postContractReservation = createAsyncThunk(
	'booking/post/contract/reservation',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/contract/reservation', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putContract = createAsyncThunk(
	'booking/put/contract',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/contract/v3', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonPayment = createAsyncThunk(
	'booking/get/payment/person',
	async (personId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/payment/person/${personId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getFacilityFeatures = createAsyncThunk(
	'booking/get/facility/features',
	async (facilityIds) => {
		let queryUrl = `/facility/features`
		facilityIds.forEach((facilityId, index) => {
			if (index === 0) queryUrl += `?id=${facilityId}`
			else queryUrl += `&id=${facilityId}`
		})
		const apiClient = new ApiClient()
		return await apiClient
			.get(queryUrl, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getFacility = createAsyncThunk(
	'booking/get/facility',
	async (facilityId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/${facilityId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getUnit = createAsyncThunk('booking/get/unit', async (unitId) => {
	const apiClient = new ApiClient()
	return await apiClient
		.get(`/unit/id/${unitId}`, null, null)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err)
		})
})

export const getUnitMembership = createAsyncThunk(
	'booking/get/unitMembership',
	async (data) => {
		const apiClient = new ApiClient()
		const { facilityId, unitId } = data
		return await apiClient
			.get(`/membership/facility/${facilityId}/unit/${unitId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getInsurance = createAsyncThunk(
	'booking/get/insurance',
	async (facilityId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/insurance/facility/${facilityId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getEncodedBookingInfo = createAsyncThunk(
	'booking/get/encodedBookingInfo',
	async (encodedString) => {
		const apiClient = new ApiClient()
		return await apiClient
			.getv3(`/reservation/booking_link/${encodedString}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const bookingSlice = createSlice({
	name: 'booking',
	initialState,
	reducers: {
		setCartOpen: (state, { payload }) => {
			state.cartOpen = payload
		},
		setProgressStepNbr: (state, { payload }) => {
			state.progressStepNbr = payload
		},
		setCustomerInfo: (state, { payload }) => {
			state.customer.first_name = payload.first_name
			state.customer.last_name = payload.last_name
			state.customer.reservation_notification = payload.reservation_notification
			state.customer.full_legal_name = payload.full_legal_name
			state.customer.phone_number = payload.phone_number
			state.customer.email_address = payload.email_address
			state.customer.day_of_birth = payload.day_of_birth
			state.customer.month_of_birth = payload.month_of_birth
			state.customer.year_of_birth = payload.year_of_birth
			state.customer.physical_address_street_line_1 =
				payload.physical_address_street_line_1
			state.customer.physical_address_street_line_2 =
				payload.physical_address_street_line_2
			state.customer.physical_address_city = payload.physical_address_city
			state.customer.physical_address_state = payload.physical_address_state
			state.customer.physical_address_zip_code =
				payload.physical_address_zip_code
			state.customer.military.active_status = payload.active_status
			state.customer.military.relation_to_military_member =
				payload.relation_to_military_member
			const military_active_member_name =
				payload.relation_to_military_member?.toString().toLowerCase() === 'self'
					? `${payload.first_name} ${payload.last_name}`
					: payload.military_active_member_name
			state.customer.military.military_active_member_name =
				military_active_member_name
			state.customer.military.military_id_number = payload.military_id_number
			state.customer.military.military_id_state = payload.military_id_state
			state.customer.military.commanding_officer_first_name =
				payload.commanding_officer_first_name
			state.customer.military.commanding_officer_last_name =
				payload.commanding_officer_last_name
			state.customer.military.commanding_officer_phone_number =
				payload.commanding_officer_phone_number
		},
		resetCustomerInfo: (state, { payload }) => {
			state.customer = initialState.customer
		},
		setFullLegalName: (state, { payload }) => {
			state.customer.full_legal_name = payload
		},
		setBookingMoveinDate: (state, { payload }) => {
			state.payment.move_in_date = payload
		},
		setBookingInfo: (state, { payload }) => {
			try {
				state.facility.facility_id = parseInt(payload?.facilityId)
				state.facility.unit_id = parseInt(payload?.unitId)
				state.payment.move_in_date = payload?.moveInDate
				if (payload.hasOwnProperty('insurance_id')) {
					state.payment.insurance_id = payload.insurance_id
					state.hasInsuranceSelected = false
				}
				if (payload.hasOwnProperty('membership_id')) {
					state.payment.membership_id = payload.membership_id
				}
			} catch (err) {
				// TODO
				console.log(err)
			}
		},
		setCustomerPersonId: (state, { payload }) => {
			state.customer.person_id = payload
		},
		setPricingWidgetMoveInDate: (state, { payload }) => {
			state.pricingWidget.pricing_widget_move_in_date = payload
		},
		setPricingWidgetLocation: (state, { payload }) => {
			state.pricingWidget.pricing_widget_location = payload
		},
		setPricingWidgetSelectedUnit: (state, { payload }) => {
			state.pricingWidget.pricing_widget_selected_unit = payload
		},
		setInsuranceId: (state, { payload }) => {
			state.payment.insurance_id = payload
		},
		setMembershipId: (state, { payload }) => {
			state.payment.membership_id = payload
		},
		setHasInsuranceSelected: (state, { payload }) => {
			state.hasInsuranceSelected = payload
		},
		resetPostPersonStatus: (state) => {
			state.postPersonStatus = null
		},
		resetPutPersonStatus: (state) => {
			state.putPersonStatus = null
		},
		resetSendOnboardingOTPStatus: (state) => {
			state.postSendOnboardingOTPStatus = null
		},
		resetVerifyOnboardingOTPStatus: (state) => {
			state.postVerifyOnboardingOTPStatus = null
		},
		resetPostCreateCustomerStatus: (state) => {
			state.postCreateCustomerStatus = null
		},
		resetPostCreateSetupIntentStatus: (state) => {
			state.postCreateSetupIntentStatus = null
		},
		resetPostCalculateMoveInEstimateStatus: (state) => {
			state.postCalculateMoveInEstimateStatus = null
		},
		resetPostWidgetCalculateMoveInEstimateStatus: (state) => {
			state.postWidgetCalculateMoveInEstimateStatus = null
		},
		resetPostVerifyPromoCodeStatus: (state) => {
			state.postVerifyPromoCodeStatus = null
		},
		resetPostContractReservationStatus: (state) => {
			state.postContractReservationStatus = null
		},
		resetPutContractStatus: (state) => {
			state.putContractStatus = null
		},
		resetGetPersonPaymentStatus: (state) => {
			state.getPersonPaymentStatus = null
		},
		resetGetFacilityFeaturesStatus: (state) => {
			state.getFacilityFeaturesStatus = null
		},
		setPaymentMethod: (state, { payload }) => {
			state.payment.payment_method = payload
		},
		resetPromoCode: (state) => {
			state.payment.promo_code = null
			state.payment.promo_id = null
			state.payment.promo_banner_text = null
		},
		resetBookingState: (state) => {
			state.payment = initialState.payment
			state.facility = initialState.facility
			state.unit = initialState.unit
			state.progressStepNbr = null
			state.insurance = []
			state.unitMembership = []
			state.hasInsuranceSelected = false
		},
		resetFacility: (state) => {
			state.facility = initialState.facility
		},
		resetGetFaciltyStatus: (state) => {
			state.getFacilityStatus = null
		},
		resetGetUnitStatus: (state) => {
			state.getUnitStatus = null
		},
		resetGetInsuranceStatus: (state) => {
			state.getInsuranceStatus = null
		},
		resetGetUnitMembershipStatus: (state) => {
			state.getUnitMembershipStatus = null
		},
		resetGetEncodedBookingInfoStatus: (state) => {
			state.getEncodedBookingInfoStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(postPerson.pending, (state) => {
				state.postPersonStatus = 'PENDING'
			})
			.addCase(postPerson.fulfilled, (state, { payload }) => {
				state.postPersonStatus = 'FULFILLED'
				state.customer.person_id = payload[0].id
			})
			.addCase(postPerson.rejected, (state, action) => {
				console.log(action)
				// If error code 2 is returned, the phone number already exists in the database
				if (action.payload.code && action.payload.code.toString() === '2') {
					state.postPersonStatus = 'REJECTED-DUPLICATE'
				} else {
					state.postPersonStatus = 'REJECTED'
				}
			})
			.addCase(putPerson.pending, (state) => {
				state.putPersonStatus = 'PENDING'
				state.putPersonError = {}
			})
			.addCase(putPerson.fulfilled, (state, { payload }) => {
				state.putPersonStatus = 'FULFILLED'
				state.putPersonError = {}
			})
			.addCase(putPerson.rejected, (state, action) => {
				state.putPersonStatus = 'REJECTED'
				state.putPersonError = action.error
			})
			.addCase(sendOnboardingOTP.pending, (state) => {
				state.postSendOnboardingOTPStatus = 'PENDING'
			})
			.addCase(sendOnboardingOTP.fulfilled, (state, { payload }) => {
				state.postSendOnboardingOTPStatus = 'FULFILLED'
			})
			.addCase(sendOnboardingOTP.rejected, (state, action) => {
				state.postSendOnboardingOTPStatus = 'REJECTED'
			})
			.addCase(verifyOnboardingOTP.pending, (state) => {
				state.postVerifyOnboardingOTPStatus = 'PENDING'
			})
			.addCase(verifyOnboardingOTP.fulfilled, (state, { payload }) => {
				state.postVerifyOnboardingOTPStatus = 'FULFILLED'
				state.customer.phone_number_verified = true
				state.onboardingOTP = payload.token //New token has user info inside the JWT
			})
			.addCase(verifyOnboardingOTP.rejected, (state, action) => {
				state.postVerifyOnboardingOTPStatus = 'REJECTED'
			})
			.addCase(postCreateCustomer.pending, (state) => {
				state.postCreateCustomerStatus = 'PENDING'
			})
			.addCase(postCreateCustomer.fulfilled, (state, { payload }) => {
				state.postCreateCustomerStatus = 'FULFILLED'
				state.payment.stripe_customer_id = payload[0].stripe_customer_id
			})
			.addCase(postCreateCustomer.rejected, (state, action) => {
				state.postCreateCustomerStatus = 'REJECTED'
			})
			.addCase(postCreateSetupIntent.pending, (state) => {
				state.postCreateSetupIntentStatus = 'PENDING'
			})
			.addCase(postCreateSetupIntent.fulfilled, (state, { payload }) => {
				state.postCreateSetupIntentStatus = 'FULFILLED'
				state.payment.si_secret = payload.client_secret
				state.payment.stripe_options.clientSecret = payload.client_secret
			})
			.addCase(postCreateSetupIntent.rejected, (state, action) => {
				state.postCreateSetupIntentStatus = 'REJECTED'
			})
			.addCase(postCalculateMoveInEstimate.pending, (state) => {
				state.postCalculateMoveInEstimateStatus = 'PENDING'
			})
			.addCase(postCalculateMoveInEstimate.fulfilled, (state, { payload }) => {
				state.postCalculateMoveInEstimateStatus = 'FULFILLED'

				state.payment.total_due_on_move_in = numberUtil.formatDollarAmount(
					payload.total
				)
				state.payment.current_month_payment = numberUtil.formatDollarAmount(
					payload.firstMonthPayment
				)
				state.payment.move_in_credit = numberUtil.formatDollarAmount(
					payload.moveInCredits
				)
				state.payment.first_month_pma = numberUtil.formatDollarAmount(
					payload.firstMonthPMA
				)
				state.payment.after_19th_move_in_payment =
					numberUtil.formatDollarAmount(payload.after19thMoveInPayment)
				state.payment.credit_end_date = payload.creditEndDate
				state.payment.next_month_payment = numberUtil.formatDollarAmount(
					payload.secondMonthPayment
				)
				state.payment.next_month_credit = numberUtil.formatDollarAmount(
					payload.secondMonthCredit
				)
				state.payment.next_month_payment_date = payload.secondMonthDueDate
				state.payment.after_19th_rate = payload.after19thRate
				state.payment.first_month_rate = payload.firstMonthRate
				state.payment.first_month_insurance_premium =
					payload.firstMonthInsurancePremium
				state.payment.after_19th_insurance_premium =
					payload.after19thInsurancePremium
				state.payment.first_Month_Membership = payload.firstMonthMembership
				state.payment.after_19th_Membership = payload.after19thMembership
				state.payment.membershipPrice = payload.membershipPrice
				state.payment.insurancePremium = payload.insurancePremium
				state.payment.paymentSchedule = payload.paymentSchedule
			})
			.addCase(postCalculateMoveInEstimate.rejected, (state, action) => {
				state.postCalculateMoveInEstimateStatus = 'REJECTED'
			})
			.addCase(postWidgetCalculateMoveInEstimate.pending, (state) => {
				state.postWidgetCalculateMoveInEstimateStatus = 'PENDING'
			})
			.addCase(
				postWidgetCalculateMoveInEstimate.fulfilled,
				(state, { payload }) => {
					state.postWidgetCalculateMoveInEstimateStatus = 'FULFILLED'

					state.widgetEstimate.total_due_on_move_in =
						numberUtil.formatDollarAmount(payload.total)
					state.widgetEstimate.current_month_payment =
						numberUtil.formatDollarAmount(payload.firstMonthPayment)
					state.widgetEstimate.move_in_credit = numberUtil.formatDollarAmount(
						payload.moveInCredits
					)
					state.widgetEstimate.first_month_pma = numberUtil.formatDollarAmount(
						payload.firstMonthPMA
					)
					state.widgetEstimate.after_19th_move_in_payment =
						numberUtil.formatDollarAmount(payload.after19thMoveInPayment)
					state.widgetEstimate.next_month_payment =
						numberUtil.formatDollarAmount(payload.secondMonthPayment)
					state.widgetEstimate.next_month_credit =
						numberUtil.formatDollarAmount(payload.secondMonthCredit)
					state.widgetEstimate.next_month_payment_date =
						payload.secondMonthDueDate
					state.widgetEstimate.after_19th_rate = payload.after19thRate
					state.widgetEstimate.first_month_rate = payload.firstMonthRate
				}
			)
			.addCase(postWidgetCalculateMoveInEstimate.rejected, (state) => {
				state.postWidgetCalculateMoveInEstimateStatus = 'REJECTED'
			})
			.addCase(putSetDefaultPaymentMethod.pending, (state) => {
				// Nothing to do
			})
			.addCase(putSetDefaultPaymentMethod.fulfilled, (state, { payload }) => {
				// Nothing to do
			})
			.addCase(putSetDefaultPaymentMethod.rejected, (state, action) => {
				// If there is a failure, let the StufOS API handle it
			})
			.addCase(postVerifyPromoCode.pending, (state) => {
				state.postVerifyPromoCodeStatus = 'PENDING'
			})
			.addCase(postVerifyPromoCode.fulfilled, (state, { payload }) => {
				state.postVerifyPromoCodeStatus = 'FULFILLED'
				state.payment.promo_id = payload.id
				state.payment.promo_code = payload.promo_code
				state.payment.promo_banner_text = payload.description
			})
			.addCase(postVerifyPromoCode.rejected, (state) => {
				state.postVerifyPromoCodeStatus = 'REJECTED'
			})
			.addCase(postContractReservation.pending, (state) => {
				state.postContractReservationStatus = 'PENDING'
			})
			.addCase(postContractReservation.fulfilled, (state, { payload }) => {
				state.postContractReservationStatus = 'FULFILLED'
				state.customer.contract_id = payload.contract_id
				logFBEvent(firebaseEventTypes.dealCreated)
			})
			.addCase(postContractReservation.rejected, (state) => {
				state.postContractReservationStatus = 'REJECTED'
			})
			.addCase(putContract.pending, (state) => {
				state.putContractStatus = 'PENDING'
			})
			.addCase(putContract.fulfilled, (state, { payload }) => {
				state.putContractStatus = 'FULFILLED'
			})
			.addCase(putContract.rejected, (state) => {
				state.putContractStatus = 'REJECTED'
			})
			.addCase(getPersonPayment.pending, (state) => {
				state.getPersonPaymentStatus = 'PENDING'
			})
			.addCase(getPersonPayment.fulfilled, (state, { payload }) => {
				state.getPersonPaymentStatus = 'FULFILLED'
				// If payment method id exists, save it
				if (!stringUtil.isNullOrUndefinedV2(payload.payment_method_id)) {
					state.payment.payment_method = payload.payment_method_id
				}
				// If stripe customer id exists, save it
				if (!stringUtil.isNullOrUndefinedV2(payload.stripe_customer_id)) {
					state.payment.stripe_customer_id = payload.stripe_customer_id
				}
			})
			.addCase(getPersonPayment.rejected, (state) => {
				state.getPersonPaymentStatus = 'REJECTED'
			})
			.addCase(getFacilityFeatures.pending, (state) => {
				state.getFacilityFeaturesStatus = 'PENDING'
			})
			.addCase(getFacilityFeatures.fulfilled, (state, { payload }) => {
				state.getFacilityFeaturesStatus = 'FULFILLED'
				state.facility.facility_feature_flags = payload
			})
			.addCase(getFacilityFeatures.rejected, (state) => {
				state.getFacilityFeaturesStatus = 'REJECTED'
			})
			.addCase(getFacility.pending, (state) => {
				state.getFacilityStatus = 'PENDING'
			})
			.addCase(getFacility.fulfilled, (state, { payload }) => {
				state.getFacilityStatus = 'FULFILLED'
				state.facility = { ...state.facility, ...payload }
				state.payment.discount_id = payload.discount_id
			})
			.addCase(getFacility.rejected, (state) => {
				state.getFacilityStatus = 'REJECTED'
			})
			.addCase(getUnit.pending, (state) => {
				state.getUnitStatus = 'PENDING'
			})
			.addCase(getUnit.fulfilled, (state, { payload }) => {
				state.getUnitStatus = 'FULFILLED'
				state.unit = payload
				state.payment.monthly_price = numberUtil.formatDollarAmount(
					payload[0]?.price,
					2
				)
			})
			.addCase(getUnit.rejected, (state) => {
				state.getUnitStatus = 'REJECTED'
			})
			.addCase(getInsurance.pending, (state) => {
				state.getInsuranceStatus = 'PENDING'
			})
			.addCase(getInsurance.fulfilled, (state, { payload }) => {
				state.getInsuranceStatus = 'FULFILLED'
				state.insurance = payload
			})
			.addCase(getInsurance.rejected, (state) => {
				state.getInsuranceStatus = 'REJECTED'
			})
			.addCase(getUnitMembership.pending, (state) => {
				state.getUnitMembershipStatus = 'PENDING'
			})
			.addCase(getUnitMembership.fulfilled, (state, { payload }) => {
				state.unitMembership = payload
				state.getUnitMembershipStatus = 'FULFILLED'
			})
			.addCase(getUnitMembership.rejected, (state) => {
				state.getUnitMembershipStatus = 'REJECTED'
			})
			.addCase(getEncodedBookingInfo.pending, (state) => {
				state.getEncodedBookingInfoStatus = 'PENDING'
			})
			.addCase(getEncodedBookingInfo.fulfilled, (state, { payload }) => {
				state.getEncodedBookingInfoStatus = 'FULFILLED'
				state.getEncodedBookingInfoError = null
				state.facility.facility_id = parseInt(payload.facility_id)
				state.facility.unit_id = parseInt(payload.unit_id)
				state.payment.move_in_date = payload.move_in_date
				state.customer.phone_number = payload.member_phone_number?.slice(1)
				state.payment.promo_id = payload.promo_id
				state.payment.discount_id = payload.discount_id
				state.payment.promo_code = payload.promo_code
			})
			.addCase(getEncodedBookingInfo.rejected, (state) => {
				state.getEncodedBookingInfoStatus = 'REJECTED'
			})
	}
})
const { actions, reducer } = bookingSlice
export const {
	setCartOpen,
	setProgressStepNbr,
	setCustomerInfo,
	setFullLegalName,
	setBookingMoveinDate,
	setBookingInfo,
	setCustomerPersonId,
	setPricingWidgetMoveInDate,
	setPricingWidgetLocation,
	setPricingWidgetSelectedUnit,
	setInsuranceId,
	setMembershipId,
	setHasInsuranceSelected,
	resetPostPersonStatus,
	resetPutPersonStatus,
	resetSendOnboardingOTPStatus,
	resetVerifyOnboardingOTPStatus,
	resetCustomerInfo,
	resetPostCreateCustomerStatus,
	resetPostCreateSetupIntentStatus,
	resetPostCalculateMoveInEstimateStatus,
	resetPostWidgetCalculateMoveInEstimateStatus,
	resetPostVerifyPromoCodeStatus,
	resetPostContractReservationStatus,
	resetPutContractStatus,
	resetGetPersonPaymentStatus,
	resetGetFacilityFeaturesStatus,
	resetFacility,
	setPaymentMethod,
	resetPromoCode,
	resetBookingState,
	resetGetFaciltyStatus,
	resetGetUnitStatus,
	resetGetInsuranceStatus,
	resetGetUnitMembershipStatus,
	resetGetEncodedBookingInfoStatus
} = actions
export default reducer
