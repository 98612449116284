import Image from 'next/image'
import Link from 'next/link'

export function Oops() {
	return (
		<div className='h-screen font-serif flex items-center justify-center'>
			<div>
				<p className='text-brand-charcoal-light text-center text-lg px-6 lg:px-16'>
					Oops...something went wrong. The Stuf team is on it!
				</p>
				<div className='flex items-center justify-center flex-1'>
					<Link href={'/'}>
						<Image
							src={'/logo-horizontal.svg'}
							width={122}
							height={32}
							alt='Stuf Logo'
							className='w-32 pt-6'
						/>
					</Link>
				</div>
			</div>
		</div>
	)
}
