import axios from 'axios'
import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'

export const initialState = {
	reviews: [],
	rating: 0,
	getReviewsStatus: null,
	availableStorage: [],
	getAvailableStorageStatus: null,
	nearbyLocations: [],
	getNearbyLocationsStatus: null,
	cityFacilities: [],
	getCityFacilitiesStatus: null,
	stateFacilities: [],
	getStateFacilitiesStatus: null
}

export const getReviews = createAsyncThunk(
	'location/getReviews',
	async (url) => {
		return await axios
			.get(url, null, null)
			.then((resp) => resp.data)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getAvailableStorage = createAsyncThunk(
	'location/getAvailableStorage',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/v3/${id}/units/available`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getNearbyLocations = createAsyncThunk(
	'location/getNearbyLocations',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/${id}/nearby`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getCityFacilities = createAsyncThunk(
	'location/getCityFacilities',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/city/${id}/facilities?fields=units`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getStateFacilities = createAsyncThunk(
	'location/getStateFacilities',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/state/${id}/facilities?fields=units`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const locationSlice = createSlice({
	name: 'location',
	initialState,
	reducers: {
		resetGetReviews: (state) => {
			;(state.reviews = null), (state.rating = 0)
		},
		resetGetAvailableStorage: (state) => {
			state.availableStorage = []
		},
		resetGetAvailableStorageStatus: (state) => {
			state.getAvailableStorageStatus = null
		},
		resetGetNearbyLocationsStatus: (state) => {
			state.getNearbyLocationsStatus = null
		},
		resetGetReviewsStatus: (state) => {
			state.getReviewsStatus = null
		},
		resetGetCityFacilitiesStatus: (state) => {
			state.getCityFacilitiesStatus = null
		},
		resetGetStateFacilitiesStatus: (state) => {
			state.getStateFacilitiesStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getReviews.pending, (state, { payload }) => {
				state.getReviewsStatus = 'PENDING'
			})
			.addCase(getReviews.fulfilled, (state, { payload }) => {
				state.getReviewsStatus = 'FULFILLED'
				state.reviews = payload.results
				state.rating =
					payload.results.reduce(
						(sum, review) => sum + parseInt(review.ratingValue),
						0
					) / payload.results.length
			})
			.addCase(getReviews.rejected, (state, action) => {
				state.getReviewsStatus = 'REJECTED'
			})
			.addCase(getAvailableStorage.pending, (state) => {
				state.getAvailableStorageStatus = 'PENDING'
			})
			.addCase(getAvailableStorage.fulfilled, (state, { payload }) => {
				state.getAvailableStorageStatus = 'FULFILLED'
				state.availableStorage = payload
			})
			.addCase(getAvailableStorage.rejected, (state) => {
				state.getAvailableStorageStatus = 'REJECTED'
			})
			.addCase(getNearbyLocations.pending, (state) => {
				state.getNearbyLocationsStatus = 'PENDING'
			})
			.addCase(getNearbyLocations.fulfilled, (state, { payload }) => {
				state.getNearbyLocationsStatus = 'FULFILLED'
				state.nearbyLocations = payload
			})
			.addCase(getNearbyLocations.rejected, (state) => {
				state.getNearbyLocationsStatus = 'REJECTED'
			})
			.addCase(getCityFacilities.pending, (state) => {
				state.getCityFacilitiesStatus = 'PENDING'
			})
			.addCase(getCityFacilities.fulfilled, (state, { payload }) => {
				state.cityFacilities = payload.filter((facility) => facility.public)
				state.getCityFacilitiesStatus = 'FULFILLED'
			})
			.addCase(getCityFacilities.rejected, (state) => {
				state.getCityFacilitiesStatus = 'REJECTED'
			})
			.addCase(getStateFacilities.pending, (state) => {
				state.getStateFacilitiesStatus = 'PENDING'
			})
			.addCase(getStateFacilities.fulfilled, (state, { payload }) => {
				state.getStateFacilitiesStatus = 'FULFILLED'
				state.stateFacilities = payload.filter((facility) => facility.public)
			})
			.addCase(getStateFacilities.rejected, (state) => {
				state.getStateFacilitiesStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = locationSlice
export const {
	resetGetReviews,
	resetGetAvailableStorage,
	resetGetAvailableStorageStatus,
	resetGetNearbyLocationsStatus,
	resetGetReviewsStatus,
	resetGetCityFacilitiesStatus,
	resetGetStateFacilitiesStatus
} = actions
export default reducer
