import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer as ToastifyContainer } from 'react-toastify'

const contextClass = {
	success: {
		toast: 'bg-brand-confirmation-green-bg',
		body: 'text-brand-confirmation-green'
	},
	error: {
		toast: 'bg-brand-alert-red-bg',
		body: 'text-brand-alert-red'
	},
	info: {
		toast: 'bg-brand-dark-blue',
		body: 'text-brand-white'
	},
	// Default to 'info' type
	default: {
		toast: 'bg-brand-dark-blue',
		body: 'text-brand-white'
	}
}

const ToastContainer = () => {
	return (
		<ToastifyContainer
			theme='colored'
			closeButton={false}
			toastClassName={({ type }) =>
				contextClass[type || 'default'].toast +
				' relative flex p-2 my-2 rounded-md justify-between overflow-hidden cursor-pointer'
			}
			bodyClassName={({ type }) =>
				contextClass[type || 'default'].body +
				' flex text-md font-serif font-bold p-3'
			}
			// TODO: Figure out how to change progress bar color. This overrides some important classes
			// from react-toastify that prevent progress bar from appearing correctly
			// progressClassName={({ type }) =>
			// 	contextClass[type || 'default'].toast
			// }
			position='bottom-right'
			autoClose={3500}
		/>
	)
}

export default ToastContainer
