import React from 'react'

import { Oops } from './oops'
import { firebaseEventTypes } from '../util/constants'
import { logFBEvent } from '../util/firebase'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		// Log the error to firebase
		logFBEvent(firebaseEventTypes.pageLoad, {
			content_type: error.message,
			content_id: 'home'
		})
	}

	render() {
		if (this.state.hasError) {
			return <Oops />
		}
		return this.props.children
	}
}

export default ErrorBoundary
