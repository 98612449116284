import { DateTime } from 'luxon'
import dateUtil from './date'
import stringUtil from './string'

function getUniqueCities(facilityList) {
	// Get a list of cities, including duplicates
	let cityNameList = []
	facilityList.forEach((facility) => {
		const cityObj = {
			id: facility.city_id,
			city_name: facility.city_name,
			state_name: facility.state_name,
			city_image: facility.city_image ? facility.city_image : null
		}

		//Check if city/state pair are already in the list
		if (!cityNameList.some((item) => item.city_name === facility.city_name)) {
			cityNameList.push(cityObj)
		}
	})

	return cityNameList
}

//Get the facility which is closest to the user based on latitude and longitude
//Only works when user grants geolocation permission
function getClosestFacility(lat, long, facilityList, isOpen) {
	let closest = {
		id: null,
		distance: 0
	}

	for (const [key, value] of Object.entries(facilityList)) {
		value.forEach((facility) => {
			if (!isOpen || (isOpen && dateUtil.hasDatePassed(facility.open_date))) {
				const latCalc = Math.abs(
					parseFloat(facility.latitude) - parseFloat(lat)
				)
				const longCalc = Math.abs(
					parseFloat(facility.longitude) - parseFloat(long)
				)
				const distance = latCalc + longCalc

				// Add the first one to the list
				if (closest.id === null) {
					console.log(
						`Finding your closest facility. First add is: ${facility.id}`
					)
					closest.id = facility.id
					closest.distance = distance
				} else if (distance < closest.distance) {
					console.log(
						`Found a closer facility. ${closest.id} is closer than ${facility.id} `
					)
					closest.id = facility.id
					closest.distance = distance
				}
			}
		})
	}

	return closest
}

function groupFacilitiesByCity(facilityList) {
	let formattedList = {}
	facilityList.forEach((facility) => {
		if (formattedList[facility.city_id]) {
			formattedList[facility.city_id].push(facility)
		} else {
			formattedList[facility.city_id] = [facility]
		}
	})

	return formattedList
}

function groupFacilitiesByState(facilityList) {
	let formattedList = {}
	facilityList.forEach((item) => {
		const stateAbbr = item.state_abbreviation
		if (!formattedList[stateAbbr]) {
			formattedList[stateAbbr] = []
		}
		formattedList[stateAbbr].push(item)
	})

	return formattedList
}

function getFacilityIdsPerCity(facilities) {
	if (!facilities) return
	let facilityIds = []

	facilities.forEach((facility) => {
		facilityIds.push(facility.id)
	})
	return facilityIds
}

function groupFacilitiesById(facilityList) {
	let formattedList = {}
	facilityList.forEach((facility) => {
		formattedList[facility.id] = facility
	})

	return formattedList
}

function getFacilityIdFromName(facilities, facilityName) {
	let id = null
	facilities.forEach((facility) => {
		if (
			facility.address_url.toLowerCase().trim() ===
			facilityName.toLowerCase().trim()
		) {
			id = facility.id
		}
	})
	return id
}

function formatFacilitiesForMap(facilities, city_name) {
	let locationsList = []
	if (facilities && Object.values(facilities).length > 0) {
		Object.values(facilities).map((facility) => {
			// If city is not passed in, add all facilities
			locationsList.push({
				address: {
					lat: Number(facility.latitude), //Value must be a number type, keep all decimals
					lng: Number(facility.longitude) //Value must be a number type, keep all decimals
				},
				id: facility.id,
				city: stringUtil.formatStringForUrl(facility.city_name),
				displayAddress: facility.address,
				uriPath: facility.address_url,
				title: facility.city_name,
				metro: facility.metro_name,
				price:
					facility.starting_at?.small ||
					facility.starting_at?.medium ||
					facility.starting_at?.large ||
					'WAITLIST'
			})
		})
	}

	return locationsList
}

function formatEntrances(value) {
	if (!value) return
	let formattedObj = {}
	const entrances = value.split('(entrance)')
	for (let i = 0; i < entrances.length; i++) {
		if (!entrances[i + 1]) break
		formattedObj[i] = {}
		const titles = entrances[i + 1].split('(title)')
		for (let t = 0; t < titles.length; t++) {
			if (!titles[t + 1]) break
			const text = titles[t + 1].split('(text)')
			formattedObj[i] = {
				title: text[t].trim(),
				text: text[t + 1].trim()
			}
		}
	}
	return formattedObj
}

function formatBulletPoints(value) {
	if (!value) return
	let formattedObj = {}
	const points = value.split('(bullet)')
	for (let i = 0; i < points.length; i++) {
		if (!points[i + 1]) break
		formattedObj[i] = {
			text: points[i + 1].trim()
		}
	}
	return formattedObj
}

function sortFacilitiesByAddress(facilities) {
	const facilitiesDeepCopy = JSON.parse(JSON.stringify(facilities))

	return facilitiesDeepCopy.sort((a, b) =>
		a.address > b.address ? 1 : b.address > a.address ? -1 : 0
	)
}

function isInsideFacilityHours(timezone, operatingHours) {
	// Check if a timezone string was passed
	if (!timezone || !operatingHours) return false
	// Check if the passed value is a valid Luxon timezone
	if (!DateTime.local().setZone(timezone).isValid) return false

	// Create a Luxon datetime obj with the passed timezone
	let dateTime = DateTime.local().setZone(timezone)
	// Identify which day of the week we're analyzing
	let today = operatingHours.find((item) => item?.day === dateTime.weekday)
	if (!today) return false

	// Abstract hours, mins and seconds from open & close time
	const [openHrs, openMins, openSecs] = today?.open_time?.split(':')
	const [closeHrs, closeMins, closeSecs] = today?.close_time?.split(':')

	// Convert open & close time to luxon datetime obj
	const openTime = DateTime.local().setZone(timezone).set({
		hour: openHrs,
		minute: openMins,
		second: openSecs
	})
	const closeTime = DateTime.local().setZone(timezone).set({
		hour: closeHrs,
		minute: closeMins,
		second: closeSecs
	})
	return openTime <= dateTime && dateTime <= closeTime
}
function getLocationsOptions(nearbyLocations, currentLocation) {
	if (!nearbyLocations || !currentLocation) return
	const transformedLocations = nearbyLocations.reduce(
		(accumulator, location, index) => {
			// Check if all starting_at values are null and there is no available storage
			const hasAvailableUnits = Object.values(location.starting_at).some(
				(value) => value !== null
			)

			if (hasAvailableUnits) {
				accumulator.push({
					label: location.business_name,
					value: location.id,
					locationURL: `/locations/${stringUtil.formatStringForUrl(
						location.city_name
					)}/${location.address_url}`,
					average_rating: location?.average_rating || 0.0,
					marketing_tag: location?.marketing_tag || '',
					...location
				})
			}

			return accumulator
		},
		[]
	)

	// If no locations with available units were found, set available units flag to false and return only the current location
	if (transformedLocations.length === 0) {
		return [
			{
				label: currentLocation.business_name,
				value: currentLocation.id,
				locationURL: `/locations/${stringUtil.formatStringForUrl(
					currentLocation.city_name
				)}/${currentLocation.address_url}`,
				average_rating: currentLocation?.average_rating || 0.0,
				marketing_tag: currentLocation?.marketing_tag || '',
				...currentLocation
			}
		]
	}

	// Add the current location to the beginning of the array
	transformedLocations.unshift({
		label: currentLocation.business_name,
		value: currentLocation.id,
		locationURL: `/locations/${stringUtil.formatStringForUrl(
			currentLocation.city_name
		)}/${currentLocation.address_url}`,
		average_rating: currentLocation?.average_rating || 0.0,
		marketing_tag: currentLocation?.marketing_tag || '',
		...currentLocation
	})

	return transformedLocations
}

export default {
	getUniqueCities,
	getClosestFacility,
	groupFacilitiesByCity,
	groupFacilitiesByState,
	getFacilityIdsPerCity,
	getLocationsOptions,
	groupFacilitiesById,
	getFacilityIdFromName,
	formatFacilitiesForMap,
	formatEntrances,
	formatBulletPoints,
	sortFacilitiesByAddress,
	isInsideFacilityHours
}
