import { createTheme } from '@mui/material/styles'

// Export palette separately to use it outside React components
export const palette = {
	// TODO: refactor to follow theme palette convention. Docs: https://mui.com/material-ui/customization/palette/
	primary: {
		main: '#1642F0',
		blue: '#1642F0',
		orange: '#D96541',
		white: '#FFFFFF',
		black: '#000000',
		'seashell-white': '#F5F3EE',
		'dark-blue': '#0C1E7D',
		'slate-blue': '#5964C9',
		periwinkle: '#D9DDF0',
		salmon: 'rgb(246,189,163)',
		'salmon-light': '#FEF8F6',
		mint: '#D8EAE3',
		yellow: '#F7CA6B',
		green: '#55C942',
		purple: '#7D3FBF',
		red: '#C94242',
		neon: '#EAFF57',
		mauve: '#D29490',
		powder: 'hsl(216.8,63.3%,90.4%)',
		coral: '#FD8F76',
		charcoal: '#3A3B3E',
		'dark-grey-2': '#57595C',
		'neighbor-blue': 'rgb(0, 121, 225)',
		'charcoal-light': '#5D5C5C',
		'light-grey': '#F6F7FB',
		'light-grey-2': '#73757B',
		'light-grey-3': '#9CA3AF',
		'light-grey-4': '#F3F4F6',
		'medium-grey': '#C0C0C0',
		'dark-grey': '#A9A9A9',
		'bright-grey': '#EAEDF7',
		'page-bg': '#FCFCFC',
		'light-silver': '#D9D9D9',
		error: '#DC3D2D',
		'error-bg': '#FEA397',
		success: '#246D4A',
		'success-bg': '#D6EAE3',
		warning: '#ffb74d',
		redLight: '#FFEDED',
		redDark: '#B74646',
		beige: '#F8F2E3',
		'eerie-black': '#1D1E20',
		graphite: '#73757B',
		'dark-green': '#00AD45'
	},
	secondary: {
		main: '#F8F8F8'
	},
	text: {
		primary: '#343333',
		boldFontWeight: 'bold'
	}
}
// TODO: add typescript for custom theme suggestions
export default createTheme({
	palette,
	shape: {
		borderRadiusFullRounded: 9999,
		borderWidth: 2
	},
	typography: {
		fontFamily: ['GT America', 'Source Serif Pro'].join(','),
		button: {
			textTransform: 'none',
			fontWeight: 700
		}
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
			'2xl': 1536
		}
	},
	components: {
		MuiFormHelperText: {
			styleOverrides: {
				root: { marginLeft: 0 }
			}
		},
		MuiRating: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'carousel' && {
						color: theme.palette.primary.beige,
						position: 'absolute'
					})
				}),
				iconEmpty: { color: 'inherit' }
			}
		},
		MuiButton: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'stuf-outline' && {
						borderRadius: '50px',
						fontWeight: 400,
						fontSize: '1rem',
						padding: '0.5rem 1.3rem',
						color: theme.palette.primary.main,
						border: '1px solid',
						borderColor: theme.palette.primary.main
					}),
					...(ownerState.variant === 'stuf-outline-white' && {
						borderRadius: '50px',
						fontWeight: 400,
						fontSize: '1rem',
						padding: '0.5rem 1.3rem',
						color: theme.palette.primary.white,
						border: '1px solid #FFFFFF',
						borderColor: theme.palette.primary.white
					})
				})
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'slider' && {
						color: 'black',
						backgroundColor: 'white',
						border: '1px solid',
						borderColor: theme.palette.primary['medium-grey'],
						fontSize: '16px',
						padding: '8px 24px',
						boxShadow: '4px 4px 4px 0px rgba(0, 76, 151, 0.04)'
					})
				}),
				arrow: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'slider' && {
						color: 'white',
						'&&::before': {
							border: '1px solid',
							borderColor: theme.palette.primary['medium-grey']
						}
					})
				})
			}
		}
	}
})
