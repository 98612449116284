import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
import newsUtil from '../util/news'

export const initialState = {
	getNewsByOrgIdStatus: null,
	featuredArticles: [],
	articles: []
}

/*
	THUNKS
*/

export const getNewsByOrgId = createAsyncThunk(
	'news/getNewsByOrgId',
	async (orgId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/news/org/${orgId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

/*
	REDUCERS
*/
export const newsSlice = createSlice({
	name: 'news',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getNewsByOrgId.pending, (state) => {
				state.getNewsByOrgIdStatus = 'PENDING'
			})
			.addCase(getNewsByOrgId.fulfilled, (state, { payload }) => {
				state.getNewsByOrgIdStatus = 'FULFILLED'
				const allArticles = newsUtil.groupArticlesByFeatured(payload)
				state.featuredArticles = allArticles?.featuredArticles
				state.articles = allArticles?.articles
			})
			.addCase(getNewsByOrgId.rejected, (state, action) => {
				state.getNewsByOrgIdStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = newsSlice
export const { } = actions
export default reducer
