function groupArticlesByFeatured(articles) {
	let formattedList = {
		featuredArticles: [],
		articles: []
	}

	articles.map((article) => {
		if (article.featured) {
			formattedList.featuredArticles.push(article)
		} else {
			formattedList.articles.push(article)
		}
	})
	return formattedList
}

export default {
	groupArticlesByFeatured
}
