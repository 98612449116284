import { initialState as initialStateBooking } from '../../slices/bookingSlice'
import { initialState as initialDashboard } from '../../slices/dashboardSlice'
import { initialState as initialStateHome } from '../../slices/HomeSlice'
import { initialState as initialMemberDashboard } from '../../slices/memberDashboardSlice'
import { initialState as initialStateRoot } from '../../slices/rootSlice'
import { initialState as initialStateSearch } from '../../slices/searchSlice'
import { initialState as initialAdminDashboard } from '../../slices/adminDashboardSlice'
import { initialState as initialLocation } from '../../slices/locationSlice'
import { initialState as initialAudit } from '../../slices/auditSlice'

const migrations = {
	38: (state) => {
		// migration to reset root, Home, and booking state
		return {
			root: initialStateRoot,
			Home: initialStateHome,
			booking: initialStateBooking,
			search: initialStateSearch,
			dashboard: initialDashboard,
			memberDashboard: initialMemberDashboard
		}
	},
	39: (state) => {
		// migration to reset dashboardSlice
		return {
			dashboard: initialDashboard
		}
	},
	40: (state) => {
		// migration to reset memberDashboardSlice
		return {
			memberDashboard: initialMemberDashboard
		}
	},
	41: (state) => {
		//migration to reset adminDashboardSlice
		return {
			adminDashboard: initialAdminDashboard
		}
	},
	42: (state) => {
		//migration to reset rootSlice
		return {
			root: initialStateRoot
		}
	},
	43: (state) => {
		//migration to reset rootSlice
		return {
			root: initialStateRoot
			//add pricing widget to current migration
		}
	},
	44: (state) => {
		// migration to add pricingWidget to bookingSlice
		// add metros to adminDashboardSlice
		return {
			...state,
			booking: {
				...state.booking,
				pricingWidget: {
					pricing_widget_move_in_date: null,
					pricing_widget_selected_unit: null,
					pricing_widget_location: null
				}
			},
			adminDashboard: {
				...state.adminDashboard,
				metros: []
			}
		}
	},
	45: (state) => {
		return {
			location: initialLocation
		}
	},
	46: (state) => {
		//migration to reset locationSlice
		return {
			location: initialLocation
		}
	},
	47: (state) => {
		//migration to reset the booking state
		return {
			booking: initialStateBooking
		}
	},
	48: (state) => {
		//migration to reset search state
		return {
			search: initialStateSearch
		}
	},
	49: (state) => {
		//migration to reset search state
		return {
			search: initialStateSearch
		}
	},
	50: (state) => {
		//migration to reset the booking state
		return {
			booking: initialStateBooking
		}
	},
	51: (state) => {
		//migration to reset the booking state
		return {
			booking: initialStateBooking
		}
	},
	52: (state) => {
		// migration to reset memberDashboardSlice
		return {
			memberDashboard: initialMemberDashboard
		}
	},
	53: (state) => {
		// migration to reset memberDashboardSlice
		return {
			memberDashboard: initialMemberDashboard
		}
	},
	54: (state) => {
		//migration to reset locationSlice
		return {
			location: initialLocation
		}
	},
	55: (state) => {
		// migration to reset memberDashboardSlice
		return {
			memberDashboard: initialMemberDashboard
		}
	},
	56: (state) => {
		//migration to reset adminDashboardSlice
		return {
			adminDashboard: initialAdminDashboard
		}
	},
	57: (state) => {
		//migration to reset search state
		return {
			search: initialStateSearch
		}
	},
	58: (state) => {
		//migration to reset the booking state
		return {
			booking: initialStateBooking
		}
	},
	59: (state) => {
		//migration to reset adminDashboardSlice
		return {
			adminDashboard: initialAdminDashboard
		}
	},
	60: (state) => {
		//migration to reset adminDashboardSlice
		return {
			booking: initialStateBooking
		}
	},
	61: (state) => {
		//migration to reset adminDashboardSlice
		return {
			adminDashboard: initialAdminDashboard
		}
	},
	62: (state) => {
		// migration to add auditSlice
		return {
			...state,
			audit: initialAudit
		}
	},
	63: (state) => {
		// migration to reset auditSlice
		return {
			audit: initialAudit
		}
	},
	64: (state) => {
		//migration to reset adminDashboardSlice
		return {
			adminDashboard: initialAdminDashboard
		}
	},
	65: (state) => {
		// migration to reset auditSlice
		return {
			audit: initialAudit
		}
	}
}

export { migrations }
