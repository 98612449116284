// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import {
	fetchAndActivate,
	getRemoteConfig,
	getValue
} from 'firebase/remote-config'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let analytics
let remoteConfig

// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
	authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
	storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
	appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
	measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID
}

export function init() {
	console.log(process.env)
	console.log(
		`Initializing Firebase for ${process.env.NEXT_PUBLIC_FIREBASE_APP_ID}`
	)
	const app = initializeApp(firebaseConfig)
	analytics = getAnalytics(app)
	remoteConfig = getRemoteConfig(app)
	// Fetch firebase changes every 10 mins in prod, 30 sec in stage
	remoteConfig.settings.minimumFetchIntervalMillis =
		process.env.NEXT_PUBLIC_ENV === 'local' ? 30000 : 600000
	remoteConfig.defaultConfig = {
		// Default values for remote config flags
		// If firebase connection fails, these will be the in-app default values
		v2_location_page: false,
		booking_pricing_widget: false,
		location_available_soon_units: false,
		homepage_search_v2: false
	}
}

export function logFBEvent(name, data) {
	logEvent(analytics, name, data)
}

export async function getRemoteConfigValue(remoteConfigKey) {
	let remoteConfigValue

	try {
		await fetchAndActivate(remoteConfig)
		const res = getValue(remoteConfig, remoteConfigKey)
		remoteConfigValue = res._value
	} catch (error) {
		console.error(
			`Firebase Remote Config failed to get value ${remoteConfigKey}: ${error}`
		)
	}

	return remoteConfigValue
}
