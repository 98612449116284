import { CONTRACT, STYLES } from './constants'
import stringUtil from './string'

function groupContractByFacility(contractList) {
	let formattedFacilityList = {}

	contractList.forEach((contract) => {
		if (formattedFacilityList[contract.facility_id]) {
			formattedFacilityList[contract.facility_id].push(contract)
		} else {
			formattedFacilityList[contract.facility_id] = [contract]
		}
	})

	let formattedList = { ...formattedFacilityList }

	Object.entries(formattedFacilityList).forEach(([key, value]) => {
		let list = {}
		value.map((contract) => {
			list[contract.unit_id] = contract
		})
		formattedList[key] = list
	})

	return formattedList
}

function getContractStatusText(statusId) {
	if (stringUtil.isNullOrUndefinedV2(statusId)) return ''
	let text = ''
	Object.values(CONTRACT.STATUS).map((contract) => {
		if (contract.id.toString() === statusId.toString()) {
			text = contract.name_desc
		}
	})
	return text
}

function getStatusStyle(statusId) {
	if (stringUtil.isNullOrUndefinedV2(statusId)) return ''
	let style = ''
	switch (statusId) {
		case 0:
			style = STYLES.roundedGreen
			break
		case 1:
			style = STYLES.roundedGrey
			break
		case 2:
			style = STYLES.roundedRed
			break
		case 3:
			style = STYLES.roundedGrey
			break
		case 4:
			style = STYLES.roundedBlue
			break
		default:
			break
	}
	return style
}

export default {
	groupContractByFacility,
	getContractStatusText,
	getStatusStyle
}
