import stringUtil from './string'

function getCityIdFromName(cities, cityName) {
	if(!cities || !cityName) return
	let id = null
	cities.forEach((city) => {
		if (
			stringUtil.formatStringForUrl(city.city_name) ===
			stringUtil.formatStringForUrl(cityName)
		) {
			id = city.id
		}
	})
	return id
}

function getCityInfoFromId(cities, id) {
	let info = {}
	cities.forEach((city) => {
		if (city.id.toString() === id.toString()) {
			info = city
		}
	})
	return info
}

export default {
	getCityIdFromName,
	getCityInfoFromId
}
