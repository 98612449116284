import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { firebaseEventTypes, firebaseEvents } from '../util/constants'
import facilityUtil from '../util/facility'
import { store } from '../store'
import ApiClient from '../util/apiClient'
import dateUtil from '../util/date'
import featuresUtil from '../util/features'
import { logFBEvent } from '../util/firebase'
import axios from 'axios'

export const initialState = {
	getPersonFeaturesStatus: null,
	getAuthenticationStatus: null,
	getVerifyLoginOTPStatus: null,
	getUnitsByFacilityIdStatus: null,
	getFacilitiesStatus: null,
	getFacilitiesByOrgStatus: null,
	getAllUnitsStatus: null,
	getPersonStatus: null,
	getPersonByPhoneNbrStatus: null,
	putFacilityInfoStatus: null,
	getReviewsStatus: null,
	authToken: '',
	isLoggedIn: false,
	user: {
		geolocationPermissionStatus: null,
		latitude: null,
		longitude: null,
		closestFacility: null,
		phone_number: null,
		personId: null,
		emailAddress: null,
		firstName: null,
		lastName: null,
		nameInitials: null,
		features: {},
		dashboardType: null,
		contracts: [],
		onboard_status: null,
		personalInfo: {
			first_name: null,
			last_name: null,
			email_address: null,
			profile_image: null,
			photo_id: null,
			physical_address_street_line_1: null,
			physical_address_street_line_2: null,
			physical_address_city: null,
			physical_address_state: null,
			active_military_status: null,
			government_id_number: null,
			government_id_state: null,
			phone_number_verified: null,
			phone_number: null,
			physical_address_zip_code: null,
			org_id: null,
			military_commanding_officer_first_name: null,
			military_id_number: null,
			military_commanding_officer_number: null,
			military_active_member_name: null,
			date_of_birth: null,
			military_member_relationship: null,
			military_commanding_officer_last_name: null,
			military_state_of_issue: null
		}
	},
	facilities: [],
	facilitiesTS: null,
	facilitiesRaw: [],
	facilitiesByAddress: [],
	facilitiesByState: [],
	cities: [],
	unitsForFacility: [],
	allUnits: [],
	reviews: [],
	metro_facilities: []
}

/*
	THUNKS
*/

export const getFacilities = createAsyncThunk(
	'root/getFacilities',
	async () => {
		// Call API if 1 hour has passed
		const ts = store.getState().root.facilitiesTS
			? JSON.parse(store.getState().root.facilitiesTS)
			: null

		// if (store.getState().root.facilities.length === 0 || dateUtil.hasHourPassed(1, ts)) {
		const apiClient = new ApiClient()
		return await apiClient
			.get('/facility', null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
		// } else {
		//     console.log('skipping getFacilities', store.getState().root.facilities)
		// }
	}
)

export const getFacilitiesByOrgV2 = createAsyncThunk(
	'root/getFacilitiesByOrgV2',
	async (params) => {
		const { orgId, fields } = params
		let queryUrl = `/facility/v2/org/${orgId}`
		if (fields) queryUrl += `?fields=${fields.join()}`

		const apiClient = new ApiClient()
		return await apiClient
			.get(queryUrl, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putFacilityInfo = createAsyncThunk(
	'root/put/facilityInfo',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`/facility`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getAllUnits = createAsyncThunk('root/getAllUnits', async () => {
	const apiClient = new ApiClient()
	return await apiClient
		.get('/unit', null, null)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err)
		})
})

export const getUnitsByFacilityId = createAsyncThunk(
	'root/getUnitsByFacilityId',
	async (facilityId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/unit/facility/${facilityId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const checkGeolocationPermissionStatus = createAsyncThunk(
	'root/checkGeolocationPermissionStatus',
	async () => {
		return new Promise((resolve, reject) => {
			if ('geolocation' in navigator) {
				navigator.permissions.query({ name: 'geolocation' }).then((resp) => {
					if (resp.state === 'denied') {
						reject('User denied geolocation permissions')
					} else if (resp.state === 'prompt' || resp.state === 'granted') {
						navigator.geolocation.getCurrentPosition(
							(position) => {
								resolve({
									lat: position.coords.latitude,
									long: position.coords.longitude
								})
							},
							(error) => {
								reject('User denied geolocation permissions')
							},
							() => {}
						)
					}
				})
			} else {
				reject('Geolocation is not enabled for this browser')
			}
		})
	}
)

export const postPersonAuthenticate = createAsyncThunk(
	'root/postPersonAuthenticate',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/person/v2/authenticate', null, body)
			.then((resp) => {
				return resp
			})
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPerson = createAsyncThunk(
	'root/get/person',
	async (personId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/id/${personId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonByPhoneNbr = createAsyncThunk(
	'root/get/personByPhoneNbr',
	async (phoneNumber) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/phone/${phoneNumber}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPersonFeatures = createAsyncThunk(
	'root/get/person/features',
	async (personId, body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/features/${personId}`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const verifyLoginOTP = createAsyncThunk(
	'root/verifyLoginOTP',
	async (body, { rejectWithValue }) => {
		const apiClient = new ApiClient()

		const verifyResponse = await apiClient
			.postv2('/person/verify-login-otp', null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(JSON.stringify(rejectWithValue(err).payload))
			})

		// TODO: move to getPersonFeatures thunk so it can be called
		// separately from verifyLogin
		const featuresResponse = await apiClient.get(
			`/person/features/${verifyResponse.personId}`
		)

		const dashboardType = featuresUtil.getPersonType(featuresResponse)

		return { ...verifyResponse, features: featuresResponse, dashboardType }
	}
)

export const getReviews = createAsyncThunk('root/getReviews', async () => {
	return await axios
		.get(
			' https://www.local-marketing-reports.com/external/showcase-reviews/widgets/a6ebabff6a5203dcd840241f42b1152c1a0ceca7',
			null,
			null
		)
		.then((resp) => resp.data)
		.catch((err) => {
			throw new Error(err)
		})
})

/*
	HELPER FUNCTIONS
*/

const setCustomerInfo = (state, payload) => {
	state.user.emailAddress = payload.email_address
	state.user.personId = payload.id
	state.user.firstName = payload.first_name
	state.user.lastName = payload.last_name
	state.user.phone_number = payload.phone_number
	state.user.nameInitials = `${payload.first_name.charAt(
		0
	)}${payload.last_name.charAt(0)}`

	state.user.personalInfo.first_name = payload.first_name
	state.user.personalInfo.last_name = payload.last_name
	state.user.personalInfo.phone_number = payload.phone_number
	state.user.personalInfo.phone_number_verified = payload.phone_number_verified
	state.user.personalInfo.email_address = payload.email_address
	state.user.personalInfo.date_of_birth = dateUtil.formatDateSlashesMMDDYYYYV2(
		payload.date_of_birth
	)
	state.user.personalInfo.profile_image = payload.profile_image
	state.user.personalInfo.photo_id = payload.photo_id
	state.user.personalInfo.physical_address_street_line_1 =
		payload.physical_address_street_line_1
	state.user.personalInfo.physical_address_street_line_2 =
		payload.physical_address_street_line_2
	state.user.personalInfo.physical_address_city = payload.physical_address_city
	state.user.personalInfo.physical_address_state =
		payload.physical_address_state
	state.user.personalInfo.physical_address_zip_code =
		payload.physical_address_zip_code
	state.user.personalInfo.government_id_number = payload.government_id_number
	state.user.personalInfo.government_id_state = payload.government_id_state
	state.user.personalInfo.org_id = payload.org_id
	state.user.personalInfo.has_pwd = payload.has_pwd
	state.user.personalInfo.active_military_status =
		payload.active_military_status
	state.user.personalInfo.military_commanding_officer_first_name =
		payload.military_commanding_officer_first_name
	state.user.personalInfo.military_id_number = payload.military_id_number
	state.user.personalInfo.military_commanding_officer_number =
		payload.military_commanding_officer_number
	state.user.personalInfo.military_active_member_name =
		payload.military_active_member_name
	state.user.personalInfo.military_member_relationship =
		payload.military_member_relationship
	state.user.personalInfo.military_commanding_officer_last_name =
		payload.military_commanding_officer_last_name
	state.user.personalInfo.military_state_of_issue =
		payload.military_state_of_issue
}
/*
	REDUCERS
*/
export const rootSlice = createSlice({
	name: 'root',
	initialState,
	reducers: {
		setClosestFacility: (state, { payload }) => {
			state.user.closestFacility = payload
		},
		setPhoneNumber: (state, { payload }) => {
			state.user.phone_number = payload
		},
		setLoggedIn: (state, { payload }) => {
			state.isLoggedIn = payload
		},
		setPersonId: (state, { payload }) => {
			state.user.personId = payload
		},
		setDashboardType: (state, { payload }) => {
			state.user.dashboardType = payload
		},
		setAuthToken: (state, { payload }) => {
			state.authToken = payload
		},
		addContract: (state, { payload }) => {
			// Deep copy contracts
			let contracts = JSON.parse(JSON.stringify(state.user.contracts))
			contracts.push(payload)
			state.user.contracts = contracts
		},
		logoutUser: (state, { payload }) => {
			state.isLoggedIn = false
			state.authToken = ' ' //Reset authToken JWT, a new one needs to be fetched without user info inside JWT
			state.getVerifyLoginOTPStatus = null
			state.user.phone_number = null
			state.user.personId = null
			state.user.emailAddress = null
			state.user.firstName = null
			state.user.lastName = null
			state.user.nameInitials = null
			state.user.contracts = []
		},
		resetAuthenticationStatus: (state) => {
			state.getAuthenticationStatus = null
		},
		resetVerifyLoginOTPStatus: (state) => {
			state.getVerifyLoginOTPStatus = null
		},
		resetGetPersonStatus: (state) => {
			state.getPersonStatus = null
		},
		resetGetPersonByPhoneNbrStatus: (state) => {
			state.getPersonByPhoneNbrStatus = null
		},
		resetPutFacilityInfoStatus: (state) => {
			state.putFacilityInfoStatus = null
		},
		resetGetReviewsStatus: (state) => {
			state.getReviewsStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getFacilities.rejected, (state) => {
				state.getFacilitiesStatus = 'REJECTED'
			})
			.addCase(getFacilities.pending, (state) => {
				state.getFacilitiesStatus = 'PENDING'
			})
			.addCase(getFacilities.fulfilled, (state, { payload }) => {
				// Note - fulfilled is still called event when API doesn't get called due to 1 hour wait limit
				if (payload) {
					state.getFacilitiesStatus = 'FULFILLED'
					state.facilitiesTS = JSON.stringify(new Date()) // Note - dates have to be stored as strings in redux
					state.facilities = facilityUtil.groupFacilitiesByCity(payload)
					state.facilitiesRaw = payload
					state.facilitiesByAddress =
						facilityUtil.sortFacilitiesByAddress(payload)
					state.cities = facilityUtil.getUniqueCities(payload)
				}
			})
			.addCase(getFacilitiesByOrgV2.rejected, (state, action) => {
				state.getFacilitiesByOrgStatus = 'REJECTED'
			})
			.addCase(getFacilitiesByOrgV2.pending, (state) => {
				state.getFacilitiesByOrgStatus = 'PENDING'
			})
			.addCase(getFacilitiesByOrgV2.fulfilled, (state, { payload }) => {
				// Note - fulfilled is still called event when API doesn't get called due to 1 hour wait limit
				if (payload) {
					state.getFacilitiesByOrgStatus = 'FULFILLED'
					state.facilitiesTS = JSON.stringify(new Date()) // Note - dates have to be stored as strings in redux
					state.facilities = facilityUtil.groupFacilitiesByCity(payload)
					state.facilitiesByState = facilityUtil.groupFacilitiesByState(payload)
					state.facilitiesRaw = payload
					state.facilitiesByAddress =
						facilityUtil.sortFacilitiesByAddress(payload)
					state.cities = facilityUtil.getUniqueCities(payload)
				}
			})
			.addCase(getAllUnits.pending, (state) => {
				state.getAllUnitsStatus = 'PENDING'
			})
			.addCase(getAllUnits.fulfilled, (state, { payload }) => {
				state.getAllUnitsStatus = 'FULFILLED'
				state.allUnits = payload
			})
			.addCase(getAllUnits.rejected, (state) => {
				state.getAllUnitsStatus = 'REJECTED'
			})
			.addCase(getUnitsByFacilityId.pending, (state) => {
				state.getUnitsByFacilityIdStatus = 'PENDING'
				//console.log(state); //TODO Get facility ID and pass it in as inputParams
			})
			.addCase(getUnitsByFacilityId.fulfilled, (state, { payload }) => {
				state.getUnitsByFacilityIdStatus = 'FULFILLED'
				state.unitsForFacility = payload
			})
			.addCase(getUnitsByFacilityId.rejected, (state) => {
				state.getUnitsByFacilityIdStatus = 'REJECTED'
			})
			.addCase(
				checkGeolocationPermissionStatus.fulfilled,
				(state, { payload }) => {
					state.user.geolocationPermissionStatus = 1
					state.user.latitude = payload.lat
					state.user.longitude = payload.long
					logFBEvent(firebaseEventTypes.selectContent, {
						content_type: firebaseEvents.geolocationPermissionState,
						content_id: 'granted'
					})
				}
			)
			.addCase(checkGeolocationPermissionStatus.rejected, (state) => {
				state.user.geolocationPermissionStatus = 0
				logFBEvent(firebaseEventTypes.selectContent, {
					content_type: firebaseEvents.geolocationPermissionState,
					content_id: 'denied'
				})
			})
			.addCase(postPersonAuthenticate.pending, (state) => {
				state.getAuthenticationStatus = 'PENDING'
			})
			.addCase(postPersonAuthenticate.fulfilled, (state, { payload }) => {
				state.getAuthenticationStatus = 'FULFILLED'
			})
			.addCase(postPersonAuthenticate.rejected, (state, action) => {
				state.getAuthenticationStatus = 'REJECTED'
			})
			.addCase(verifyLoginOTP.pending, (state) => {
				state.getVerifyLoginOTPStatus = 'PENDING'
				state.loginErrorCode = null
			})
			.addCase(verifyLoginOTP.fulfilled, (state, { payload }) => {
				state.getVerifyLoginOTPStatus = 'FULFILLED'
				state.isLoggedIn = true
				state.authToken = payload.token //New token has user info inside the JWT
				state.user.emailAddress = payload.emailAddress
				state.user.personId = payload.personId
				state.user.firstName = payload.firstName
				state.user.lastName = payload.lastName
				state.user.nameInitials = `${payload.firstName.charAt(
					0
				)}${payload.lastName.charAt(0)}`
				state.user.onboard_status = payload.onboard_status

				// TODO: move to a getPersonFeatures thunk
				state.user.features = payload.features
				state.user.dashboardType = payload.dashboardType
				state.loginErrorCode = null
			})
			.addCase(verifyLoginOTP.rejected, (state, action) => {
				state.getVerifyLoginOTPStatus = 'REJECTED'
				state.isLoggedIn = false
				state.loginErrorCode = JSON.parse(action.error.message)?.code || null
			})
			.addCase(getPersonFeatures.pending, (state) => {
				state.getPersonFeaturesStatus = 'PENDING'
			})
			.addCase(getPersonFeatures.fulfilled, (state, { payload }) => {
				state.getPersonFeaturesStatus = 'FULFILLED'
				state.user.features = payload
				state.user.dashboardType = featuresUtil.getPersonType(payload)
			})
			.addCase(getPersonFeatures.rejected, (state, action) => {
				state.getPersonFeaturesStatus = 'REJECTED'
			})
			.addCase(getPerson.pending, (state) => {
				state.getPersonStatus = 'PENDING'
			})
			.addCase(getPerson.fulfilled, (state, { payload }) => {
				state.getPersonStatus = 'FULFILLED'
				// Set personal info
				setCustomerInfo(state, payload)
			})
			.addCase(getPerson.rejected, (state, action) => {
				state.getPersonStatus = 'REJECTED'
			})
			.addCase(getPersonByPhoneNbr.pending, (state) => {
				state.getPersonByPhoneNbrStatus = 'PENDING'
			})
			.addCase(getPersonByPhoneNbr.fulfilled, (state, { payload }) => {
				state.getPersonByPhoneNbrStatus = 'FULFILLED'
				// Set personal info
				setCustomerInfo(state, payload)
			})
			.addCase(getPersonByPhoneNbr.rejected, (state, action) => {
				state.getPersonByPhoneNbrStatus = 'REJECTED'
			})
			.addCase(putFacilityInfo.pending, (state) => {
				state.putFacilityInfoStatus = 'PENDING'
			})
			.addCase(putFacilityInfo.fulfilled, (state, { payload }) => {
				state.putFacilityInfoStatus = 'FULFILLED'
			})
			.addCase(putFacilityInfo.rejected, (state, action) => {
				state.putFacilityInfoStatus = 'REJECTED'
			})
			.addCase(getReviews.rejected, (state, action) => {
				state.getReviewsStatus = 'REJECTED'
			})
			.addCase(getReviews.pending, (state) => {
				state.getReviewsStatus = 'PENDING'
			})
			.addCase(getReviews.fulfilled, (state, { payload }) => {
				state.getReviewsStatus = 'FULFILLED'
				// Set reviews
				state.reviews = payload?.results
			})
	}
})

/*
	LISTENERS
*/

// Listener to user.closestFacility updates
let currentClosestFacility
export function handleClosestFacilityChange() {
	let previousValue = currentClosestFacility
	currentClosestFacility = store.getState().root.user.closestFacility

	if (previousValue !== currentClosestFacility) {
		if (currentClosestFacility !== null) {
			// Dispatch the API call to get all available units for that facility
			store.dispatch(getUnitsByFacilityId(currentClosestFacility.id))
		}
	}
}

// Listener to user.geolocationPermissionStatus and facilities updates. The objective is to find the closest facility
// to the user once they grant geolocation permissions
let currentGeolocationPermissions
let currentFacilities
export function handleGeolocationPermissionsChange() {
	let previousValue = currentGeolocationPermissions
	let previousFacilities = currentFacilities
	currentGeolocationPermissions =
		store.getState().root.user.geolocationPermissionStatus
	currentFacilities = store.getState().root.facilities

	if (
		previousValue !== currentGeolocationPermissions ||
		previousFacilities !== currentFacilities
	) {
		if (
			currentGeolocationPermissions !== null &&
			currentGeolocationPermissions === 1 &&
			Object.keys(currentFacilities).length > 0
		) {
			// User granted GeolocationPermissions permissions
			// Get the closest facility to the user
			const facility = facilityUtil.getClosestFacility(
				store.getState().root.user.latitude,
				store.getState().root.user.longitude,
				currentFacilities,
				true
			)
			store.dispatch(setClosestFacility(facility))
			// Log which facility id the user is closest to
			logFBEvent(firebaseEventTypes.selectContent, {
				content_type: firebaseEvents.geolocationPermissionState,
				content_id: facility
			})
		}
	}
}

const { actions, reducer } = rootSlice
export const {
	setClosestFacility,
	logoutUser,
	resetAuthenticationStatus,
	resetVerifyLoginOTPStatus,
	resetGetPersonStatus,
	resetGetPersonByPhoneNbrStatus,
	setPhoneNumber,
	setLoggedIn,
	setPersonId,
	setDashboardType,
	setAuthToken,
	addContract,
	resetPutFacilityInfoStatus,
	resetGetReviewsStatus
} = actions
export default reducer
