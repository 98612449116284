import { v4 as uuidv4 } from 'uuid'

function formatPhoneNumber(phoneNumber) {
	if (!phoneNumber) return
	let trimmed = ('' + phoneNumber).replace(/\D/g, '')
	let regex = trimmed.match(/^(\d{3})(\d{3})(\d{4})$/)
	if (regex) {
		return '(' + regex[1] + ') ' + regex[2] + '-' + regex[3]
	}
	return
}

function formatPhoneNumberV2(phoneNumber) {
	if (!phoneNumber) return
	let trimmed = ('' + phoneNumber).replace(/\D/g, '')
	if (phoneNumber.length === 10) {
		let regex = trimmed.match(/^(\d{3})(\d{3})(\d{4})$/)
		if (regex) {
			return `(${regex[1]})${regex[2]}-${regex[3]}`
		}
	} else if (phoneNumber.length === 11) {
		let regex = trimmed.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
		if (regex) {
			return `${regex[1]} (${regex[2]}) ${regex[3]}-${regex[4]}`
		}
	}

	return
}

const formatPhoneNumberProgress = (value) => {
	if (!value) return

	// clean the input for any non-digit values.
	const phoneNumber = value.replace(/[^\d]/g, '')

	// phoneNumberLength is used to know when to apply our formatting for the phone number
	const phoneNumberLength = phoneNumber.length

	// we need to return the value with no formatting if its less then four digits
	// this is to avoid weird behavior that occurs if you  format the area code to early

	if (phoneNumberLength < 4) return phoneNumber

	// if phoneNumberLength is greater than 4 and less the 7 we start to return
	// the formatted number
	if (phoneNumberLength < 7) {
		return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
	}

	// if phoneNumberLength is equal to 11, a 1 is added to the front
	if (phoneNumberLength === 11) {
		return `${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
			1,
			4
		)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`
	}

	// Default case
	return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
		3,
		6
	)}-${phoneNumber.slice(6, 10)}`
}

function unFormatPhoneNumber(phoneNumber) {
	if (!phoneNumber) return
	return phoneNumber.toString().replace(/[^\d]/g, '')
}

function formatPhoneNumberforAPI(phoneNumber) {
	if (!phoneNumber) return
	// Remove formatting
	const unformatted = unFormatPhoneNumber(phoneNumber)
	// Phone number should be at least 10 digits
	if (unformatted.length < 10) return
	// If number is not 11 digits, then add a 1 at the beginning
	if (unformatted.length < 11) {
		return `1${unformatted}`
	} else {
		return unformatted
	}
}

// A function to calculate occupancy percentage
function calcPercentage(partial, total, decimals) {
	if (partial === 0 || total === 0) return 0
	if (!partial || !total) return

	let raw = ((total - partial) / total) * 100
	return `${parseFloat(raw).toFixed(decimals || 2)}`
}

// A function to calculate unit rate with percentage discount rate
function calcPercentageDiscount(total, discountRate) {
	if (!total || total === 0 || !discountRate) return
	const intTotal = toFixed(total, 2)
	const rate = parseInt(1) - discountRate / 100
	const calcTotal = parseFloat(toFixed(intTotal * rate, 2))
	return formatDollarAmount(calcTotal, 2)
}

function formatDollarAmount(amount, digits) {
	Number.prototype.toFixedDown = function (digits) {
		var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
			m = this.toString().match(re)
		return m ? parseFloat(m[1]) : this.valueOf()
	}
	String.prototype.toFixedDown = function (digits) {
		var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
			m = this.toString().match(re)
		return m ? parseFloat(m[1]) : this.valueOf()
	}
	if (amount === null || amount === undefined) return
	if (parseInt(amount) === 0) return 0.0
	amount = amount.toFixedDown(digits || 2).toLocaleString('en', {
		useGrouping: false,
		minimumFractionDigits: digits || 2
	})
	return parseFloat(amount, 10)
}

// Does not return a parseFloat number
function formatDollarAmountV2(amount, digits) {
	Number.prototype.toFixedDown = function (digits) {
		var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
			m = this.toString().match(re)
		return m ? parseFloat(m[1]) : this.valueOf()
	}
	String.prototype.toFixedDown = function (digits) {
		var re = new RegExp('(\\d+\\.\\d{' + digits + '})(\\d)'),
			m = this.toString().match(re)
		return m ? parseFloat(m[1]) : this.valueOf()
	}
	if (amount === null || amount === undefined) return
	amount = amount.toFixedDown(digits || 2).toLocaleString('en', {
		useGrouping: false,
		minimumFractionDigits: digits || 2
	})
	return amount
}

function toFixed(num, fixed) {
	var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?')
	return num.toString().match(re)[0]
}

function getUUID() {
	return uuidv4()
}

function formatPrice(number) {
	if (typeof number !== 'number') {
		return null
	}

	let prefix = ''
	if (number < 0) {
		prefix = '-'
		number = Math.abs(number)
	}

	const formattedNumber = number
		.toFixed(2)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	return prefix + '$' + formattedNumber
}

export default {
	formatPhoneNumber,
	formatPhoneNumberV2,
	formatPhoneNumberProgress,
	unFormatPhoneNumber,
	formatPhoneNumberforAPI,
	calcPercentage,
	calcPercentageDiscount,
	formatDollarAmount,
	formatDollarAmountV2,
	toFixed,
	getUUID,
	formatPrice
}
