import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
import dateUtil from '../util/date'
import unitUtil from '../util/unit'
import moment from 'moment'

export const initialState = {
	moveInDate: null,
	sizeCategory: null,
	cityOrAddress: null,
	sort: {
		direction: 'asc',
		selection: 0
	},
	filter: {
		price: null,
		height: 6,
		facility24_7: false,
		facilityDriveIn: false
	},
	getAvailableUnitsStatus: null,
	allAvailableUnits: [],
	allAvailableUnitsByCity: [],
	searchResults: [],
	cities: [],
	facilities: [],
	metros: [],
	searchResultsFacilities: [],
	availableStorage: [],
	searchResultsData: [],
	recentSearchHistory: [],
	getMetrosStatus: null,
	getFacilitiesByMetroIdStatus: null,
	getSearchResultsFacilitiesStatus: null,
	getAvailableStorageStatus: null,
	activeMetro: null,
	searchLocation: null,
	getSearchResultsStatus: null,
	filterList: {
		min: 0,
		max: 1000,
		availability: true,
		small: false,
		medium: false,
		large: false,
		parking: false,
		date: dateUtil.formatDateDashesMMDDYYYY(dateUtil.addDays(new Date(), 1))
	},
	sortBy: '',
	searchValue: ''
}

/*
	THUNKS
*/

export const setCities = createAsyncThunk('search/setCities', async () => {
	return store.getState().root.cities
})

export const setFacilities = createAsyncThunk(
	'search/setFacilities',
	async () => {
		return store.getState().root.facilities
	}
)

export const getAvailableUnits = createAsyncThunk(
	'search/getAvailableUnits',
	async (orgId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get('/unit/available', null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getMetros = createAsyncThunk('search/getMetros', async () => {
	const apiClient = new ApiClient()
	return await apiClient
		.get('metro', null, null)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err)
		})
})

export const getFacilitiesByMetroId = createAsyncThunk(
	'search/getFacilitiesByMetroId',
	async ({ id, fields }) => {
		let queryUrl = `/metro/v2/${id}/facilities?fields=units${
			fields ? fields : ''
		}`
		const apiClient = new ApiClient()
		return await apiClient
			.get(queryUrl, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getAvailableStorage = createAsyncThunk(
	'search/getAvailableStorage',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/v3/${id}/units/available`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getSearchResults = createAsyncThunk(
	'search/getSearchResults',
	async (query) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/metro/v2/search?query_string=${query}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getNearbyFacilities = createAsyncThunk(
	'search/getNearbyFacilities',
	async ({ lat, lng, fields }) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(
				`/facility/nearby?fields=units&lat=${lat}&lng=${lng}${
					fields ? fields : ''
				}`,
				null,
				null
			)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const searchSlice = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setMoveinDate: (state, { payload }) => {
			const dateOptions = { timeZone: 'UTC' }
			state.moveInDate = dateUtil.formatDateSlashesMMDDYYYY(
				payload,
				dateOptions
			)

			// Search results don't change based on move in date - maybe a future feature to show upcoming based on upcoming scheduled move outs
		},
		setSizeCategory: (state, { payload }) => {
			state.sizeCategory = payload
		},
		setCityOrAddress: (state, { payload }) => {
			state.cityOrAddress = payload
			// Update the list of available units by city each time the cityOrAddress is changed
			state.allAvailableUnitsByCity = unitUtil.filterByCityName(
				state.allAvailableUnits,
				state.cities,
				state.facilities,
				payload
			)
		},
		setSort: (state, { payload }) => {
			if (payload.direction) {
				// Ascending = Sort low to high, show up arrow
				// Descending = Sort high to low, show down arrow
				state.sort.direction = payload.direction
			}
			if (payload.selection !== undefined && payload.selection !== null) {
				state.sort.selection = payload.selection
			}

			// TODO set searchResults
		},
		setFilter: (state, { payload }) => {
			if (payload.type && payload.type !== 'sizeCategory') {
				state.filter[payload.type] = payload.value
			} else if (payload.type === 'sizeCategory') {
				state.sizeCategory = payload.value
			}
			state.searchResults = applyFilters(state)
		},
		clearAllFilters: (state) => {
			state.filter.price = null
			state.sizeCategory = ['1', '2', '3']
			state.filter.height = 6
			state.filter.facility24_7 = false
			state.filter.facilityDriveIn = false
			state.searchResults = applyFilters(state)
		},
		updateSearchResults: (state) => {
			state.searchResults = applyFilters(state)
		},
		resetGetMetrosStatus: (state) => {
			state.getMetrosStatus = null
		},
		resetGetFacilitiesByMetroIdStatus: (state) => {
			state.getFacilitiesByMetroIdStatus = null
		},
		resetGetAvailableStorage: (state) => {
			state.availableStorage = []
		},
		resetGetAvailableStorageStatus: (state) => {
			state.getAvailableStorageStatus = null
		},
		resetSearchResultsFacilities: (state) => {
			state.searchResultsFacilities = []
		},
		setActiveMetro: (state, { payload }) => {
			state.activeMetro = payload
		},
		setSearchLocation: (state, { payload }) => {
			state.searchLocation = payload
		},
		resetGetSearchResultsStatus: (state) => {
			state.getSearchResultsStatus = null
		},
		resetSearchResults: (state) => {
			state.searchResultsData = []
		},
		resetGetSearchResultsFacilitiesStatus: (state) => {
			state.getSearchResultsFacilitiesStatus = null
		},
		setRecentSearch: (state, { payload }) => {
			state.recentSearchHistory.unshift(payload)
			if (state.recentSearchHistory.length > 5) {
				state.recentSearchHistory.pop()
			}
		},
		setFilters: (state, { payload }) => {
			state.filterList = payload
		},
		setSortBy: (state, { payload }) => {
			state.sortBy = payload
		},
		setSearchValue: (state, { payload }) => {
			state.searchValue = payload
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAvailableUnits.pending, (state) => {
				state.getAvailableUnitsStatus = 'PENDING'
			})
			.addCase(getAvailableUnits.fulfilled, (state, { payload }) => {
				state.getAvailableUnitsStatus = 'FULFILLED'
				state.allAvailableUnits = payload
				state.allAvailableUnitsByCity = unitUtil.filterByCityName(
					payload,
					state.cities,
					state.facilities,
					state.cityOrAddress
				)
				state.searchResults = applyFilters(state)
			})
			.addCase(getAvailableUnits.rejected, (state, action) => {
				state.getAvailableUnitsStatus = 'REJECTED'
			})
			.addCase(setCities.fulfilled, (state, { payload }) => {
				state.cities = payload
			})
			.addCase(setFacilities.fulfilled, (state, { payload }) => {
				state.facilities = payload
			})
			.addCase(getMetros.pending, (state) => {
				state.getMetrosStatus = 'PENDING'
			})
			.addCase(getMetros.fulfilled, (state, { payload }) => {
				state.getMetrosStatus = 'FULFILLED'
				state.metros = payload
			})
			.addCase(getMetros.rejected, (state) => {
				state.getMetrosStatus = 'REJECTED'
			})
			.addCase(getFacilitiesByMetroId.pending, (state) => {
				state.getFacilitiesByMetroIdStatus = 'PENDING'
			})
			.addCase(getFacilitiesByMetroId.fulfilled, (state, { payload }) => {
				state.getFacilitiesByMetroIdStatus = 'FULFILLED'
				state.searchResultsFacilities = payload.filter(
					(facility) => facility.public
				)
			})
			.addCase(getFacilitiesByMetroId.rejected, (state) => {
				state.getFacilitiesByMetroIdStatus = 'REJECTED'
			})
			.addCase(getAvailableStorage.pending, (state) => {
				state.getAvailableStorageStatus = 'PENDING'
			})
			.addCase(getAvailableStorage.fulfilled, (state, { payload }) => {
				state.getAvailableStorageStatus = 'FULFILLED'
				state.availableStorage = payload
			})
			.addCase(getAvailableStorage.rejected, (state) => {
				state.getAvailableStorageStatus = 'REJECTED'
			})
			.addCase(getSearchResults.pending, (state) => {
				state.getSearchResultsStatus = 'PENDING'
			})
			.addCase(getSearchResults.fulfilled, (state, { payload }) => {
				state.getSearchResultsStatus = 'FULFILLED'
				state.searchResultsData = payload
			})
			.addCase(getSearchResults.rejected, (state) => {
				state.getSearchResultsStatus = 'REJECTED'
			})
			.addCase(getNearbyFacilities.pending, (state) => {
				state.getSearchResultsFacilitiesStatus = 'PENDING'
			})
			.addCase(getNearbyFacilities.fulfilled, (state, { payload }) => {
				state.getSearchResultsFacilitiesStatus = 'FULFILLED'
				state.searchResultsFacilities = payload.filter(
					(facility) => facility.public
				)
			})
			.addCase(getNearbyFacilities.rejected, (state) => {
				state.getSearchResultsFacilitiesStatus = 'REJECTED'
			})
	}
})

function applyFilters(state) {
	if (state.allAvailableUnits.length > 0) {
		const allAvailableUnits = state.allAvailableUnits
		let results = []

		/* sizeCategory */
		if (state.sizeCategory) {
			let list = []
			allAvailableUnits.forEach((unit) => {
				// Default to show parking spots when 'Any Size' filter is selected
				if (
					state.sizeCategory.includes(unit?.size_category?.toString()) ||
					(state.sizeCategory.length == 3 &&
						unit?.size_category?.toString() === '10')
				) {
					list.push(unit)
				}
			})
			results = list
		}

		/* cityOrAddress */
		if (state.cityOrAddress) {
			results =
				unitUtil.filterByCityName(
					results,
					state.cities,
					state.facilities,
					state.cityOrAddress
				) || []
		}

		/* price */
		if (state.filter.price) {
			results = unitUtil.filterByPriceV2(results, state.filter.price)
		}

		/* facility24_7 */
		if (state.filter.facility24_7) {
			// TODO
		}

		/* facilityDriveIn */
		if (state.filter.facilityDriveIn) {
			// TODO
		}

		if (results.length > 0) {
			let list = []

			// Sort by price in order to show lower price first and mimic the 'facility/v3/:id/units/available' API results
			results = results.sort((a, b) => a.price - b.price)

			// Filter out any unit with availability_date after 30 days from now
			results.forEach((unit) => {
				if (
					!Boolean(unit.availability_date) ||
					moment.utc(unit.availability_date).isBefore(moment().add(30, 'days'))
				) {
					list.push(unit)
				}
			})
			results = list
		}
		return results
	}
}

const { actions, reducer } = searchSlice
export const {
	setMoveinDate,
	setSizeCategory,
	setCityOrAddress,
	setSort,
	setFilter,
	clearAllFilters,
	updateSearchResults,
	resetGetMetrosStatus,
	resetGetAvailableStorage,
	resetGetAvailableStorageStatus,
	resetGetFacilitiesByMetroIdStatus,
	resetSearchResultsFacilities,
	setActiveMetro,
	resetGetSearchResultsStatus,
	resetSearchResults,
	resetGetSearchResultsFacilitiesStatus,
	setRecentSearch,
	setFilters,
	setSortBy,
	setSearchValue,
	setSearchLocation
} = actions
export default reducer
