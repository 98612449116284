import Axios from 'axios'
import { store } from '../store'
import { setAuthToken } from '../slices/rootSlice'
import stringUtil from './string'

// Global variable to store POST /authorize promise for multiple parallel requests failing with 401
let authTokenPromise = undefined

//TODO - Add ability to cancel a request https://axios-http.com/docs/cancellation
//Timeout is set to 15 seconds for all methods

class ApiClient {
	constructor() {
		this.allRouteHeaders = {
			Authorization: store.getState().root.authToken
				? store.getState().root.authToken
				: null,
			client_id: process.env.NEXT_PUBLIC_STUF_CLIENT_ID
		}
		this.client = this.createAxiosClient()
	}

	createAxiosClient() {
		const client = Axios.create({
			baseURL: process.env.NEXT_PUBLIC_STUF_API_URL,
			responseType: 'json',
			headers: this.allRouteHeaders,
			timeout: 15000
		})
		// Axios response interceptor for API calls. See https://axios-http.com/docs/interceptors
		client.interceptors.response.use(
			// onFulfilled callback
			(response) => {
				return response
			},
			// onError callback
			async function (error) {
				const originalRequest = error.config
				if (
					error.response.status === 401 &&
					!originalRequest._retry &&
					originalRequest.url !== '/authorize'
				) {
					// Raise the retry flag for the original request
					// Only allows 1 retry
					originalRequest._retry = true
					let tokenResp
					try {
						// If there's not a pending promise, call /authorize API to fetch an access token
						if (!authTokenPromise) {
							authTokenPromise = client.post('/authorize', null, {
								headers: {
									...client.defaults.headers,
									client_secret: process.env.NEXT_PUBLIC_STUF_CLIENT_SECRET
								}
							})
						}
						// Wait for auth promise to resolve
						// the trick here, that `authTokenPromise` is global, e.g. 2 expired requests will get the same function pointer and await same function.
						tokenResp = await authTokenPromise
					} catch (error) {
						console.error('Error while fetching Access Token: ', error)
					} finally {
						authTokenPromise = undefined
					}
					// If there is a new access token
					if (tokenResp.data.token) {
						// Set auth token in rootSlice for future requests
						store.dispatch(setAuthToken(tokenResp.data.token))
						// Set auth token for retrying the original request
						originalRequest.headers['Authorization'] = tokenResp.data.token
						// Retry the original request
						return client(originalRequest)
					}
				}
				// If POST /authorize failed, retry it a maximum of 3 times
				if (
					originalRequest.url === '/authorize' &&
					(stringUtil.isNullOrUndefined(originalRequest._retryCount) ||
						originalRequest._retryCount < 3)
				) {
					// Initialize a retryCount for the first retry attempt
					if (stringUtil.isNullOrUndefined(originalRequest._retryCount)) {
						originalRequest._retryCount = 1
					} else {
						originalRequest._retryCount += 1
					}
					// Use exponential backoff mechanism to retrying the request. See https://cloud.google.com/iot/docs/how-tos/exponential-backoff
					const delayRetryRequest = new Promise((resolve) => {
						setTimeout(() => {
							resolve()
						}, originalRequest._retryCount * 1000 || 1000)
					})
					// Retry request to POST /authorize
					return delayRetryRequest.then(() => client(originalRequest))
				}
				return Promise.reject(error)
			}
		)
		return client
	}

	async get(url, headers) {
		return await this.client
			.get(url, {
				...this.allRouteHeaders,
				...headers
			})
			.then((response) => response.data)
			.catch((error) => {
				throw new Error(error)
			})
	}

	async getv2(url, headers) {
		const combinedHeaders = {
			...this.allRouteHeaders,
			...headers
		}
		return await this.client({
			method: 'GET',
			url,
			headers: combinedHeaders
		})
			.then((response) => response.data)
			.catch((error) => {
				throw new Error(error)
			})
	}

	async getv3(url, headers) {
		return await this.client
			.get(url, {
				...this.allRouteHeaders,
				...headers
			})
			.then((response) => response.data)
			.catch((error) => {
				throw error.response.data
			})
	}

	async post(url, headers, body) {
		const combinedHeaders = {
			...this.allRouteHeaders,
			...headers
		}
		return await this.client
			.post(url, body, { headers: combinedHeaders })
			.then((response) => response.data)
			.catch((error) => {
				throw new Error(error)
			})
	}

	async postv2(url, headers, body) {
		const combinedHeaders = {
			...this.allRouteHeaders,
			...headers
		}
		return await this.client
			.post(url, body, { headers: combinedHeaders })
			.then((response) => response.data)
			.catch((error) => {
				throw error.response.data
			})
	}

	async put(url, headers, body) {
		const combinedHeaders = {
			...this.allRouteHeaders,
			...headers
		}
		return await this.client
			.put(url, body, { headers: combinedHeaders })
			.then((response) => {
				return response.data
			})
			.catch((error) => {
				throw error.response.data
			})
	}

	//Can't use delete, it's a reserved word
	async del(url, headers, body) {
		const combinedHeaders = {
			...this.allRouteHeaders,
			...headers
		}

		return await this.client
			.delete(url, { headers: combinedHeaders, data: body })
			.then((response) => response.data)
			.catch((error) => {
				throw new Error(error)
			})
	}
}

export default ApiClient
