import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import ApiClient from '../util/apiClient'

export const initialState = {
	getUnitsStatus: null,
	units: {}
}

export const HomeSlice = createSlice({
	name: 'Home',
	initialState,
	reducers: {},
	extraReducers: (builder) => { }
})

export default HomeSlice.reducer
