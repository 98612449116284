import { UNIT } from './constants'
import cityUtil from './city'
import facilityUtil from './facility'
import date from './date'
import moment from 'moment'

function groupUnitsBySizeCategory(unitList) {
	let formattedList = {}
	unitList.forEach((unit) => {
		if (formattedList[unit.size_category]) {
			formattedList[unit.size_category].push(unit)
		} else {
			formattedList[unit.size_category] = [unit]
		}
	})

	return formattedList
}

function groupBySizeCategoryAllCities(unitList) {
	let formattedList = {}

	// List is per size category
	Object.values(unitList).map((units) => {
		// Iterate units inside size category
		units.forEach((unit) => {
			let name = `${unit.facility_id}_${unit.size_category}`
			if (formattedList[name]) {
				formattedList[name].push(unit)
			} else {
				formattedList[name] = [unit]
			}
		})
	})
	return formattedList
}

function groupAllAvailableByFacility(units, facilities) {
	let formattedList = {}
	// Iterate through facilities
	facilities.forEach((facility) => {
		if (formattedList[facility.id]) {
			formattedList[facility.id].push({
				...facility,
				size_category: [],
				total: 0,
				startingAt: null
			})
		} else {
			formattedList[facility.id] = [
				{ ...facility, size_category: [], total: 0, startingAt: null }
			]
		}
	})

	// Iterate through units
	units.map((unit) => {
		const facilityId = unit.facility_id
		const facility = formattedList[facilityId]
		if (facility && facility[0]) {
			// Don't include unsellable units
			if (parseInt(unit.status) === UNIT.STATUS.STANDARD) {
				if (
					!facility[0].size_category.includes(unit.size_category.toString())
				) {
					formattedList[facilityId][0].size_category.push(
						unit.size_category.toString()
					)
					formattedList[facilityId][0].size_category.sort()
				}
				formattedList[facilityId][0].total = facility[0].total + 1
				formattedList[facilityId][0].startingAt =
					!facility[0].startingAt ||
					parseInt(unit.price) < parseInt(facility[0].startingAt)
						? parseInt(unit.price)
						: parseInt(facility[0].startingAt)
			} else {
				formattedList[facilityId][0].totalUnsellable = facility[0]
					.totalUnsellable
					? facility[0].totalUnsellable + 1
					: 1
			}
		}
	})

	// Remove facilities that have no available units
	Object.entries(formattedList).map((facility) => {
		if (facility[1][0].total === 0) {
			delete formattedList[facility[1][0].id]
		}
	})

	return formattedList
}

function groupByDimensions(unitList, facilityId) {
	let formattedList = {}
	if (unitList && facilityId) {
		unitList.map((unit) => {
			if (unit.facility_id === facilityId) {
				if (
					parseInt(unit.size_category) < 10 &&
					UNIT.SIZE_OPTIONS[parseInt(unit.size_category)]
				) {
					const sizeInfo = UNIT.SIZE_OPTIONS[parseInt(unit.size_category)]
					const obj = { ...unit, size_category_text: sizeInfo.name }
					if (formattedList[`${unit.width}x${unit.length}`]) {
						formattedList[`${unit.width}x${unit.length}`].push(obj)
					} else {
						formattedList[`${unit.width}x${unit.length}`] = [obj]
					}
				} else if (
					parseInt(unit.size_category) === 10 &&
					UNIT.SIZE_OPTIONS[parseInt(unit.size_category)]
				) {
					const sizeInfo = UNIT.SIZE_OPTIONS[parseInt(unit.size_category)]
					const obj = { ...unit, size_category_text: sizeInfo.name }
					if (formattedList[`Parking ${unit.width}x${unit.length}`]) {
						formattedList[`Parking ${unit.width}x${unit.length}`].push(obj)
					} else {
						formattedList[`Parking ${unit.width}x${unit.length}`] = [obj]
					}
				}
			}
		})
	}

	return formattedList
}

function sortByPrice(unitList) {
	if (!unitList) return []
	// let unique = Object.entries(unitList).map(unitList => unitList.length > 0 ? unitList[0]: unitList)
	let sortedList = []
	sortedList = unitList.slice().sort((a, b) => {
		return Number(a.price) - Number(b.price)
	})

	return sortedList
}

function filterByCity(unitList, facilityIds) {
	let formattedList = {}
	// List is per size category
	Object.values(unitList).map((units) => {
		// Iterate units inside size category
		units &&
			units.forEach((unit) => {
				if (
					facilityIds.includes(unit.facility_id.toString()) ||
					facilityIds.includes(parseInt(unit.facility_id))
				) {
					if (formattedList[unit.size_category]) {
						formattedList[unit.size_category].push(unit)
					} else {
						formattedList[unit.size_category] = [unit]
					}
				}
			})
	})

	return formattedList
}

function filterByCityV2(unitList, facilityIds) {
	if (!unitList || !facilityIds) return
	let list = []

	unitList?.forEach((unit) => {
		if (
			facilityIds.includes(unit.facility_id.toString()) ||
			facilityIds.includes(parseInt(unit.facility_id))
		) {
			list.push(unit)
		}
	})
	return list
}

function filterByPrice(unitList, maxPrice) {
	let formattedList = {}
	// List is per size category
	Object.values(unitList).map((units) => {
		// Iterate units inside size category
		units.forEach((unit) => {
			if (parseInt(unit.price) <= parseInt(maxPrice)) {
				if (formattedList[unit.size_category]) {
					formattedList[unit.size_category].push(unit)
				} else {
					formattedList[unit.size_category] = [unit]
				}
			}
		})
	})

	return formattedList
}

function filterByPriceV2(unitList, maxPrice) {
	let list = []

	unitList?.forEach((unit) => {
		if (parseInt(unit.price) <= parseInt(maxPrice)) {
			list.push(unit)
		}
	})

	return list
}

function filterByHeight(unitList, maxHeight) {
	let formattedList = {}
	// List is per size category
	Object.values(unitList).map((units) => {
		// Iterate units inside size category
		units.forEach((unit) => {
			if (unit.height <= maxHeight) {
				formattedList[unit.size_category].push(unit)
			}
		})
	})

	return formattedList
}

function filterByCityName(units, cities, facilities, cityOrAddress) {
	if (units && cities && facilities && cityOrAddress) {
		// 1. Get city id from city name
		const cityId = cityUtil.getCityIdFromName(cities, cityOrAddress)
		// 2. Get all facility ids in a city
		const facilityIds = facilityUtil.getFacilityIdsPerCity(facilities[cityId])
		// 3. Filter units by facility ids
		return filterByCityV2(units, facilityIds)
	}
}

function getUnitStatusId(status) {
	if (!status) return

	let statusId
	Object.entries(UNIT.STATUS).map((value) => {
		if (
			value[0].toString().toLowerCase() ===
			status.toString().toLowerCase().replace(/\s/g, '')
		) {
			statusId = value[1]
		}
	})
	return statusId
}

function getSizeCategoryId(size) {
	if (!size) return

	let sizeId
	Object.values(UNIT.SIZE_OPTIONS).map((value) => {
		if (value.name.toString().toLowerCase() === size.toString().toLowerCase()) {
			sizeId = parseInt(value.id)
		}
	})
	return sizeId
}

function getAvailableId(available) {
	if (available.toLowerCase() === 'available') {
		return true
	}
	if (available.toLowerCase() === 'occupied') {
		return false
	}
	return undefined
}

const getSizeCategoryFromId = (id) => {
	switch (id) {
		case 1:
			return 'small'
		case 2:
			return 'medium'
		case 3:
			return 'large'
		default:
			return ''
	}
}

const parseUnitHelper = (property, data) => {
	switch (property) {
		case 'unit_number':
		case 'id':
			return parseInt(data)
		case 'price':
		case 'width':
		case 'length':
		case 'height':
			return parseFloat(data)
		case 'size_category':
			return getSizeCategoryId(data)
		case 'status':
			return getUnitStatusId(data)
		case 'available':
			return getAvailableId(data).toString().toLowerCase()
		default:
			return undefined
	}
}

function getUnitsOptions(availableStorage, t) {
	if (!availableStorage) return []

	let transformedArray = [...availableStorage]
		.map((storage, index) => {
			const availableDateHasNotPassed = !date.hasDatePassed(
				storage.availableDate
			)
			let sizeLabel = t(getSizeCategoryFromId(storage.sizeCategory), {
				ns: 'common'
			})

			let unitID = null
			let unitNumber = null
			let neighborUrl = null
			if (!!storage.units && storage.units.length > 0) {
				unitID = storage.units[0].id
				unitNumber = storage.units[0].unit_number
				neighborUrl = storage.units[0].meta_tags.neighborUrl
			}
			return {
				label: `${sizeLabel} (${storage.width}×${storage.length})`,
				value: (index + 1).toString(),
				sizeCategory: storage.sizeCategory,
				width: storage.width,
				length: storage.length,
				price: storage.price,
				priceDiscount: storage.priceDiscount,
				discountID: storage.discount_id,
				unitID: unitID,
				unitNumber: unitNumber,
				tags: storage.tags,
				neighborUrl: neighborUrl,
				availableDate: moment(storage.availableDate, 'MM-DD-YYYY').format(
					'M/D/YYYY'
				),
				divider: false,
				availableSoonUnit: availableDateHasNotPassed,
				message: availableDateHasNotPassed
					? `${t('move-in-on-or-after', {
							date: date.returnMonthAndDay(storage.availableDate),
							ns: 'booking'
					  })}`
					: null
			}
		})
		.sort((a, b) => {
			// Sorting with two criteria: First if units are available soon and second by price

			//Checks if availableSoon unit boolean value is different for both elements
			if (!a.availableSoonUnit !== !b.availableSoonUnit) {
				//First criteria:
				//Sort elements by moving to the end all elements which when compared returned positive value:
				return !a.availableSoonUnit ? -1 : 1
			} // Skip first criteria if both elements have the same value for availableSoon

			//Second criteria:
			//Returns negative or positive values and places element 'a' before element 'b' or viceversa based on price difference
			//Second criteria runs only if the first criteria is skipped, which means, both elements being compared have the same boolean value in the availableSoon flag
			return a.price - b.price
		})

	const firstMessageIndex = transformedArray.findIndex(
		(item) => item.message !== null
	)

	//Insert a unit as a divider with the message: Available Soon
	if (firstMessageIndex > -1) {
		transformedArray.splice(firstMessageIndex, 0, {
			message: t('available-soon', { ns: 'common' }),
			divider: true,
			availableSoonUnit: true
		})
	}

	// Add 'Select a unit' object at the beginning
	transformedArray.unshift({
		label: 'Select a unit',
		value: '0'
	})

	return transformedArray
}

function getUnitsOptionsV2(availableStorage, t) {
	if (!availableStorage) return []

	let transformedArray = []
	availableStorage.forEach((storage) => {
		storage.units.forEach((unit) => {
			const availableDateHasNotPassed = !date.hasDatePassed(
				storage.availableDate
			)
			let sizeLabel = t(getSizeCategoryFromId(storage.sizeCategory), {
				ns: 'common'
			})

			const newObject = {
				label: `${sizeLabel} (${storage.width}×${storage.length})`,
				sizeCategory: storage.sizeCategory,
				width: storage.width,
				length: storage.length,
				price: storage.price,
				priceDiscount: storage.priceDiscount,
				discountID: storage.discount_id,
				unitID: unit.id,
				unitNumber: unit.unit_number,
				meta_tags: unit.meta_tags,
				tags: storage.tags,
				neighborUrl: unit.meta_tags.neighborUrl,
				availableDate: moment(storage.availableDate, 'MM-DD-YYYY').format(
					'M/D/YYYY'
				),
				divider: false,
				availableSoonUnit: availableDateHasNotPassed,
				message: availableDateHasNotPassed
					? `${t('move-in-on-or-after', {
							date: date.returnMonthAndDay(storage.availableDate),
							ns: 'booking'
					  })}`
					: null
			}
			transformedArray.push(newObject)
		})
	})

	transformedArray.sort((a, b) => {
		// Sorting with two criteria: First if units are available soon and second by price

		//Checks if availableSoon unit boolean value is different for both elements
		if (!a.availableSoonUnit !== !b.availableSoonUnit) {
			//First criteria:
			//Sort elements by moving to the end all elements which when compared returned positive value:
			return !a.availableSoonUnit ? -1 : 1
		} // Skip first criteria if both elements have the same value for availableSoon

		//Second criteria:
		//Returns negative or positive values and places element 'a' before element 'b' or viceversa based on price difference
		//Second criteria runs only if the first criteria is skipped, which means, both elements being compared have the same boolean value in the availableSoon flag
		// If prices are the same, sort by unit number
		if (a.price === b.price) {
			return a.unitNumber - b.unitNumber
		}

		return a.price - b.price
	})

	const firstMessageIndex = transformedArray.findIndex(
		(item) => item.message !== null
	)

	//Insert a unit as a divider with the message: Available Soon
	if (firstMessageIndex > -1) {
		transformedArray.splice(firstMessageIndex, 0, {
			message: t('available-soon', { ns: 'common' }),
			divider: true,
			availableSoonUnit: true
		})
	}

	return transformedArray
}

export default {
	groupUnitsBySizeCategory,
	groupBySizeCategoryAllCities,
	groupAllAvailableByFacility,
	groupByDimensions,
	filterByCity,
	filterByPrice,
	filterByHeight,
	filterByCityV2,
	filterByPriceV2,
	sortByPrice,
	filterByCityName,
	getUnitStatusId,
	getSizeCategoryId,
	getAvailableId,
	parseUnitHelper,
	getSizeCategoryFromId,
	getUnitsOptions,
	getUnitsOptionsV2
}
