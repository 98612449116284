import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
import { FACILITY_AUDIT } from '../util/constants'
export const initialState = {
	facilityId: null,
	runner: {
		id: null,
		firstName: null,
		lastName: null,
		status: null
	},
	validRunner: false,
	postFacilityAuditLoginStatus: null,
	loginErrorStatusCode: null,
	activeFacility: {},
	postFacilityAuditStatus: null,
	validAudit: false,
	audit: {
		facility_id: null,
		runner_id: null,
		doors_clear: null,
		clear_exits: null,
		security_notes: '',
		total_locks: '',
		total_totes: '',
		total_carts: '',
		other_amenities: '',
		inventory_notes: '',
		free_of_trash: null,
		pests: null,
		water_intrusion: null,
		visible_signage: null,
		lights_working: null,
		additional_notes: '',
		units: []
	}
}

export const postFacilityAuditLogin = createAsyncThunk(
	'audit/postFacilityAuditLogin',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit/login`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postFacilityAudit = createAsyncThunk(
	'audit/postFacilityAudit',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const auditSlice = createSlice({
	name: 'audit',
	initialState,
	reducers: {
		setValidRunner: (state, action) => {
			state.validRunner = action.payload
		},
		setActiveFacility: (state, action) => {
			state.activeFacility = action.payload
		},
		setValidAudit: (state, action) => {
			state.validAudit = action.payload
		},
		setAuditProperty: (state, action) => {
			state.audit[action.payload.key] = action.payload.value
		},
		setAuditUnitProperty: (state, action) => {
			const { index, key, value } = action.payload
			state.audit.units[index][key] = value
		},
		resetAuditState: (state) => {
			state.facilityId = null
			state.runner.id = null
			state.runner.firstName = null
			state.runner.lastName = null
			state.runner.status = null
			state.validRunner = false
			state.activeFacility = {}
		},
		resetAudit: (state) => {
			state.audit = initialState.audit
		},
		resetPostFacilityAuditLoginStatus: (state) => {
			state.postFacilityAuditLoginStatus = null
		},
		resetLogInErrorStatusCode: (state) => {
			state.loginErrorStatusCode = null
		},
		resetPostFacilityAuditStatus: (state) => {
			state.postFacilityAuditStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(postFacilityAuditLogin.pending, (state) => {
				state.postFacilityAuditLoginStatus = 'PENDING'
			})
			.addCase(postFacilityAuditLogin.fulfilled, (state, { payload }) => {
				state.postFacilityAuditLoginStatus = 'FULFILLED'
				state.facilityId = payload.facility_id
				state.runner.id = payload.id
				state.runner.firstName = payload.first_name
				state.runner.lastName = payload.last_name
				state.runner.status = payload.status
				state.validRunner =
					payload.status === FACILITY_AUDIT.RUNNER_STATUS.ACTIVE && payload.id
						? true
						: false
			})
			.addCase(postFacilityAuditLogin.rejected, (state, action) => {
				state.postFacilityAuditLoginStatus = 'REJECTED'
				console.log(action.error.message)
				state.loginErrorStatusCode = action.error.message.includes('401')
					? 401
					: null
			})
			.addCase(postFacilityAudit.pending, (state) => {
				state.postFacilityAuditStatus = 'PENDING'
			})
			.addCase(postFacilityAudit.fulfilled, (state) => {
				state.postFacilityAuditStatus = 'FULFILLED'
			})
			.addCase(postFacilityAudit.rejected, (state) => {
				state.postFacilityAuditStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = auditSlice
export const {
	setValidRunner,
	setActiveFacility,
	setAuditProperty,
	setAuditUnitProperty,
	resetAuditState,
	resetAudit,
	resetPostFacilityAuditLoginStatus,
	resetLogInErrorStatusCode,
	resetPostFacilityAuditStatus,
	setValidAudit
} = actions
export default reducer
