import { DateTime } from 'luxon'

import { DATE } from './constants'
import stringUtil from './string'

import moment from 'moment'

function hasDatePassed(date) {
	const dateToCheck = new Date(date.replace(/-/g, '/'))
	const today = new Date()

	return dateToCheck <= today
}

function hasHourPassed(nbrHours, date) {
	const dateToCheck = new Date(date.replace(/-/g, '/'))
	const today = new Date()
	const ms = nbrHours * 60 * 60 * 1000

	return today - dateToCheck > ms
}

function returnPastDate(date, daysToSubtract) {
	if (
		stringUtil.isNullOrUndefined(date) ||
		stringUtil.isNullOrUndefined(daysToSubtract)
	)
		return
	let dateSlices = date.split('-')
	let isoDate = `${dateSlices[0]} ${dateSlices[1]} ${dateSlices[2]}`
	let pastDate = DateTime.fromFormat(isoDate, 'M d yyyy')
		.minus({ days: daysToSubtract })
		.toISODate() //=> '2017-06-01'
	let slices = pastDate.split('-')
	return `${slices[1]}-${slices[2]}-${slices[0]}`
}

function returnFutureDate(daysToAdd) {
	let futureDate = new Date()
	futureDate.setDate(futureDate.getDate() + daysToAdd)
	return futureDate
}

function formatDateSlashesMMDDYYYY(dateToFormat, options) {
	if (stringUtil.isNullOrUndefined(dateToFormat)) return

	// Check if dateToFormat is typeof date
	if (Object.prototype.toString.call(dateToFormat) === '[object Date]') {
		dateToFormat = DateTime.fromJSDate(dateToFormat, {
			zone: 'utc',
			numberingSystem: 'beng'
		})
		dateToFormat = `${dateToFormat.c?.month}/${dateToFormat.c?.day}/${dateToFormat.c?.year}`
		return dateToFormat
	}
	try {
		const date = new Date(dateToFormat)
		if (stringUtil.isNullOrUndefined(date)) return
		const formattedDate = date.toLocaleDateString(
			'en-US',
			options || DATE.TIMEZONE
		)
		return formattedDate
	} catch (err) {
		console.log(`dateUtil.formatDateSlashesMMDDYYYY: ${err}`)
	}
}

function formatDateSlashesMMDDYYYYV2(dateToFormat, options) {
	if (stringUtil.isNullOrUndefined(dateToFormat)) return

	// Check if dateToFormat is typeof date
	if (Object.prototype.toString.call(dateToFormat) === '[object Date]') {
		dateToFormat = DateTime.fromJSDate(dateToFormat, {
			zone: 'utc',
			numberingSystem: 'beng'
		})
		dateToFormat = `${dateToFormat.c?.month}/${dateToFormat.c?.day}/${dateToFormat.c?.year}`
		return dateToFormat
	}
	try {
		const date = moment(dateToFormat, 'M-D-YYYY').toDate()
		if (stringUtil.isNullOrUndefined(date)) return
		const formattedDate = date.toLocaleDateString(
			'en-US',
			options || DATE.TIMEZONE
		)
		return formattedDate
	} catch (err) {
		console.log(`dateUtil.formatDateSlashesMMDDYYYYV2: ${err}`)
	}
}

function formatDateDashesMMDDYYYY(dateToFormat, options) {
	if (stringUtil.isNullOrUndefined(dateToFormat)) return

	// Check if dateToFormat is typeof date
	if (Object.prototype.toString.call(dateToFormat) === '[object Date]') {
		dateToFormat = DateTime.fromJSDate(dateToFormat, {
			zone: 'utc',
			numberingSystem: 'beng'
		})
		dateToFormat = `${dateToFormat.c?.month}-${dateToFormat.c?.day}-${dateToFormat.c?.year}`
		return dateToFormat
	}
	try {
		const date = new Date(dateToFormat)
		if (stringUtil.isNullOrUndefined(date)) return
		let formattedDate = date.toLocaleDateString(
			'en-US',
			options || DATE.TIMEZONE
		)
		formattedDate = formattedDate.replace(/\//g, '-')
		return formattedDate
	} catch (err) {
		console.log(`dateUtil.formatDateSlashesMMDDYYYY: ${err}`)
	}
}

function formatDateDashesMMDDYYYYV2(dateToFormat, options) {
	if (stringUtil.isNullOrUndefined(dateToFormat)) return

	// Check if dateToFormat is typeof date
	if (Object.prototype.toString.call(dateToFormat) === '[object Date]') {
		dateToFormat = DateTime.fromJSDate(dateToFormat, {
			zone: 'utc',
			numberingSystem: 'beng'
		})
		dateToFormat = `${dateToFormat.c?.month}-${dateToFormat.c?.day}-${dateToFormat.c?.year}`
		return dateToFormat
	}
	try {
		const date = moment(dateToFormat, 'M-D-YYYY').toDate()
		if (stringUtil.isNullOrUndefined(date)) return
		let formattedDate = date.toLocaleDateString(
			'en-US',
			options || DATE.TIMEZONE
		)
		formattedDate = formattedDate.replace(/\//g, '-')
		return formattedDate
	} catch (err) {
		console.log(`dateUtil.formatDateSlashesMMDDYYYY: ${err}`)
	}
}

function returnNextMonthFullName(date) {
	if (!date) return
	let futureDate = addMonths(date, 1)
	let slices = futureDate.split('-')
	let currentDate = DateTime.fromObject(
		{ month: slices[0], year: slices[2], hour: 0 },
		{ zone: 'utc', numberingSystem: 'beng' }
	)
	let firstDayOfMonth = currentDate.startOf('month').startOf('day')
	let firstDateOfMonth = `${firstDayOfMonth.c?.month}-${firstDayOfMonth.c?.day}-${firstDayOfMonth.c?.year}`
	return returnMonthFullName(firstDateOfMonth)
}

function returnFirstDateOfMonth(date) {
	if (!date) return
	let slices = date.split('-')
	let currentDate = DateTime.fromObject(
		{ month: slices[0], year: slices[2], hour: 0 },
		{ zone: 'utc', numberingSystem: 'beng' }
	)
	let firstDayOfMonth = currentDate.startOf('month').startOf('day')
	let firstDateOfMonth = `${firstDayOfMonth.c?.month}-${firstDayOfMonth.c?.day}-${firstDayOfMonth.c?.year}`
	return firstDateOfMonth
}

function returnFirstDateOfNextMonth(date) {
	if (!date) return
	let futureDate = addMonths(date, 1)
	let slices = futureDate.split('-')
	let currentDate = DateTime.fromObject(
		{ month: slices[0], year: slices[2], hour: 0 },
		{ zone: 'utc', numberingSystem: 'beng' }
	)
	let firstDayOfMonth = currentDate.startOf('month').startOf('day')
	let firstDateOfMonth = `${firstDayOfMonth.c?.month}-${firstDayOfMonth.c?.day}-${firstDayOfMonth.c?.year}`
	return firstDateOfMonth
}

function addMonths(date, months) {
	if (
		stringUtil.isNullOrUndefined(date) ||
		stringUtil.isNullOrUndefined(months)
	)
		return
	let dateSlices = date.split('-')
	let isoDate = `${dateSlices[0]} ${dateSlices[1]} ${dateSlices[2]}`
	let futureDate = DateTime.fromFormat(isoDate, 'M d yyyy')
		.plus({ months: months })
		.toISODate() //=> '2017-06-01'
	let slices = futureDate.split('-')
	return `${slices[1]}-${slices[2]}-${slices[0]}`
}

function addDays(date, daysToAdd) {
	if (
		stringUtil.isNullOrUndefined(date) ||
		stringUtil.isNullOrUndefined(daysToAdd)
	)
		return
	let futureDate = new Date(date)
	futureDate.setDate(futureDate.getDate() + daysToAdd)
	return futureDate
}

function returnMonthFullName(date) {
	if (!date) return
	const newDate = new Date(date.replace(/-/g, '/'))
	if (stringUtil.isNullOrUndefined(newDate)) return
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		newDate
	)
	return month
}

function returnMonthAbbrName(date) {
	if (!date) return
	const newDate = new Date(date.replace(/-/g, '/'))
	if (stringUtil.isNullOrUndefined(newDate)) return
	const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
		newDate
	)
	return month
}

function returnLongDate(date) {
	if (!date) return
	const newDate = new Date(date.replace(/-/g, '/'))
	if (stringUtil.isNullOrUndefined(newDate)) return
	const month = new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
		newDate
	)
	return month
}

function returnYear(date) {
	const newDate = new Date(date)
	const year = newDate.getFullYear()
	return year
}

function returnMonthAndDay(date) {
	if (!date) return
	const newDate = new Date(date.replace(/-/g, '/'))
	if (stringUtil.isNullOrUndefined(newDate)) return
	const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
		newDate
	)
	return `${month} ${newDate.getDate()}`
}

function returnMonthAbbrAndDay(date) {
	if (!date) return
	const newDate = new Date(date.replace(/-/g, '/'))
	if (stringUtil.isNullOrUndefined(newDate)) return
	const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(
		newDate
	)
	return `${month} ${newDate.getDate()}`
}

function returnStringDate(date) {
	if (!date) return
	return `${returnMonthAndDay(date)}, ${returnYear(date)}`
}

function formatStringWithoutTimezone(dateString) {
	if (!dateString) return
	return dateString.replace(/-/g, '/').replace(/T.+/, '')
}

function lastDayOfTheMonth(date, format = 'date') {
	let lastDay
	if (Object.prototype.toString.call(date) === '[object Date]') {
		lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
	} else {
		const today = date ? new Date(date) : new Date()
		lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0)
	}
	// Return format
	if (format === 'dashes') return formatDateDashesMMDDYYYY(lastDay)
	if (format === 'slashes') return formatDateSlashesMMDDYYYY(lastDay)
	if (format === 'long') return returnLongDate(lastDay)
	return lastDay
}

function isWithinDays(date1, date2, numDays) {
	if (!date1 || !date2 || !numDays) return
	// Get the time difference in milliseconds between the two dates
	const timeDiff = Math.abs(date1.getTime() - date2.getTime())

	// Convert the time difference to days
	const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

	// Check if the difference in days is less than or equal to numDays
	return diffDays <= numDays
}

// returns something similar to "Wednesday, July 3"
function formatDateToWeekdayMonthDay(dateInput) {
	const weekdays = [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday'
	]
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	]

	let date
	if (typeof dateInput === 'string') {
		if (dateInput.includes('/')) {
			// Handle month/day/year format
			const [month, day, year] = dateInput.split('/')
			date = new Date(Date.UTC(year, month - 1, day))
		} else if (dateInput.includes('-')) {
			// Handle month-day-year format
			const [month, day, year] = dateInput.split('-')
			date = new Date(Date.UTC(year, month - 1, day))
		} else {
			date = dateInput
		}
	} else {
		date = dateInput
	}

	const dayOfMonth = date.getUTCDate()
	const weekday = weekdays[date.getUTCDay()]
	const monthName = months[date.getUTCMonth()]

	return `${weekday}, ${monthName} ${dayOfMonth}`
}

function isDateInRange(date, startDate, range) {
	return (
		moment(date, 'M-D-YYYY').isSameOrAfter(moment(startDate, 'M/D/YYYY')) &&
		moment(date, 'M-D-YYYY').isSameOrBefore(
			moment(startDate, 'M/D/YYYY').add(range, 'days')
		)
	)
}

function isDateBetween(date, startDate, endDate) {
	return (
		moment(date, 'M-D-YYYY').isSameOrAfter(moment(startDate, 'M-D-YYYY')) &&
		moment(date, 'M-D-YYYY').isSameOrBefore(moment(endDate, 'M-D-YYYY'))
	)
}

function isAfterDate(date, referenceDate) {
	return moment(referenceDate, 'M-D-YYYY').isAfter(moment(date, 'M-D-YYYY'))
}

export default {
	hasDatePassed,
	hasHourPassed,
	returnPastDate,
	returnFutureDate,
	formatDateDashesMMDDYYYY,
	formatDateDashesMMDDYYYYV2,
	formatDateSlashesMMDDYYYY,
	formatDateSlashesMMDDYYYYV2,
	addDays,
	addMonths,
	returnMonthFullName,
	returnMonthAbbrName,
	returnYear,
	returnLongDate,
	returnNextMonthFullName,
	returnFirstDateOfMonth,
	returnFirstDateOfNextMonth,
	returnMonthAndDay,
	returnMonthAbbrAndDay,
	returnStringDate,
	formatStringWithoutTimezone,
	lastDayOfTheMonth,
	isWithinDays,
	formatDateToWeekdayMonthDay,
	isDateInRange,
	isDateBetween,
	isAfterDate
}
