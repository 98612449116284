function replaceDashesWithSpaces(string) {
	if (!string) return ''
	return string.toString().replace(/-/g, ' ')
}

function formatStringForUrl(string) {
	if (!string) return ''
	// Hardcoding case for Washington, DC
	if (isWashingtonDC(string)) {
		return 'washington-dc'
	}
	return string.toString().toLowerCase().replace(/\s+/g, '-')
}

function uppercaseFirstLetters(string) {
	if (!string) return ''
	let words = string.split(' ')

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1)
	}

	return words.join(' ')
}

function formatCityState(string) {
	if (!string) return ''
	if (string.toLowerCase().replace(/\s/g, '') === 'washington,dc,dc') {
		return 'Washington, DC'
	}
	return string
}

function isWashingtonDC(string) {
	// Hardcoding case for Washington, DC
	return (
		string.toString().toLowerCase() === 'washington, dc' ||
		string.toString().toLowerCase() === 'washington dc'
	)
}

function isNullOrUndefined(str) {
	if (str === 0) {
		// 0 registered as undefined, so forcing 0 to be valid string
		return false
	}
	return !str || str.length === 0 || str === undefined || str === null
}

function isNullOrUndefinedV2(str) {
	if (str === 0 || (typeof str === 'boolean' && str === false)) {
		// 0 registered as undefined, so forcing 0 to be valid string
		return false
	}
	return !str || str.length === 0 || str === undefined || str === null
}

// A function to compare 2 strings and determine if they are the same
function doStringsMatch(string1, string2) {
	if (isNullOrUndefined(string1) && isNullOrUndefined(string2)) return true
	if (isNullOrUndefined(string1) || isNullOrUndefined(string2)) return false
	const str1 = string1.toLowerCase()
	const str2 = string2.toLowerCase()

	return str1 === str2
}

function capitalizeFirstLetters(string) {
	if (!string) return
	// Remove any extra spaces
	string = string.trim()
	const words = string.split(' ')
	for (let i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1)
	}
	return words.join(' ')
}

function addLineBreakAfterPeriod(string) {
	if (!string) return

	// Add line breaks after periods (.)
	return string.replace(/\. /g, '.\n')
}

export default {
	replaceDashesWithSpaces,
	formatStringForUrl,
	uppercaseFirstLetters,
	formatCityState,
	isWashingtonDC,
	isNullOrUndefined,
	doStringsMatch,
	isNullOrUndefinedV2,
	capitalizeFirstLetters,
	addLineBreakAfterPeriod
}
