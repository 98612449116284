function getPersonType(features) {
    if(!features) return
    let type = ''
    for (const role in features) {
        if (type) break

        for (const feature of features[role]) {
            if (feature.feature_id === 1) {
                type = 'admin'
                break
            }
            if (feature.feature_id === 2) {
                type = 'member'
                break
            }
        }
    }
    return type
}

function checkFeature(list, id) {
    if(!list || !id) return
    let found = false
    Object.values(list).map(item => {
        if (item.constructor === Array){
            item.forEach(feature => {
                if (feature.feature_id === id) {
                    found = true
                }
            })
        }
    })

    return found
}

export default {
    getPersonType,
    checkFeature
}