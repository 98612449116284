import crypto from 'crypto'

const algorithm = "aes-256-cbc";
const securityKey = process.env.NEXT_PUBLIC_STUF_PRIVATE_KEY

function decryptOne(value, iv) {
    iv = Buffer.from(iv, 'base64')
    const key = Buffer.from(securityKey)

    // decipher function
    const decipher = crypto.createDecipheriv(algorithm, key, iv)
    // decrypt the value
    let decryptedData = decipher.update(value, "hex", "utf8")
    decryptedData += decipher.final("utf8")
    return decryptedData
}

function decryptObject(values, iv) {
    let newValues = {}

    // Iterate through the list and decrypt each item
    for (const [key, value] of Object.entries(values, iv)) {

        const decryptedData = decryptOne(value, iv)

        newValues[`${key}`] = decryptedData
    }

    return newValues
}

function encryptOne(value, iv) {
    const key = Buffer.from(securityKey)

    // cipher function
    const cipher = crypto.createCipheriv(algorithm, key, iv)

    // encrypt the value
    let encryptedData = cipher.update(value.toString(), "utf-8", "hex")

    encryptedData += cipher.final("hex")
    return encryptedData
}

function encryptObject(values, iv) {
    let newValues = {}
    // Iterate through the list and decrypt each item
    for (const [key, value] of Object.entries(values, iv)) {

        const encryptedData = encryptOne(value, iv)

        newValues[`${key}`] = encryptedData
    }

    return newValues
}

export default {
    decryptObject,
    encryptObject
}