import stringUtil from './string'

function removeEmptyKeys(list) {
    if(!list) return
    Object.keys(list).forEach(key => {
        if (!(typeof list[key] === 'boolean') && stringUtil.isNullOrUndefined(list[key])) {
            delete list[key]
        }
    })
    return list
}

export default {
    removeEmptyKeys
}